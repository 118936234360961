import { ethers } from "ethers";

export const TE_ADDR = "0x77171464ae48a2C96B3b7E513e9EEc5843207E7E";
export const CTRL_ADDR = "0x7E7E742AB1965e4d96940071f4248018287875A5";

export const OUTCOME_WATCHER_WALLET = "0xa0E8A15208fEE478E40c5bb22d8c589D0C1a62b5";

export const TE_CONSTANTS = {
	MS_PER_SEC: 1000, // JS uses ms, Solidity returns seconds
	CONFIRMATION_BLOCKS: 200,
	DEFAULT_DECIMALS: 2,
	PCT_ONE_HUNDRED: ethers.BigNumber.from(10).pow(18),
	AAVE_DENOMINATOR: ethers.BigNumber.from(10).pow(8),
	SEC_PER_WEEK: 604_800, // JS uses ms, Solidity returns seconds
	MS_PER_YEAR: 31_536_000_000,
	WITHDRAW_NOW_SLIPPAGE: "0.01",
	TRADER_SLIPPAGE: 0.0035,
	TRADER_SLIPPAGE_BPS: 35,
	ZeroXAPIKeyHeader_v1: { "0x-api-key": "a42f1547-fe23-403f-bf27-f3b03229d78d" }, // Free v1 API key
	ZeroXAPIKeyHeader_v2: { "0x-api-key": "a42f1547-fe23-403f-bf27-f3b03229d78d" }, // Free v2 API key // TODO !!! REPLACE !!!
	// AvvyDomains: "0x1ea4e7A798557001b99D88D6b4ba7F7fc79406A9", // only on the Avalanche C-Chain

	// https://www.rapidtables.com/convert/number/hex-to-decimal.html
};

export type INetwork = {
	chainId: string;
	name: string;
	nativeCurrency: { name: string; decimals: ethers.BigNumber; denominator: ethers.BigNumber; };
	rpcURL: string;
	blockExplorerURL: string;
	blockExplorerForTokenURL: string;
	base0xURL_v1: string;
	base0xURL_v2: string;
	ZeroXExchangeProxy: string;
	USDC: string;
	Coin: string;
	WCoin: string;
	BTC: string;
	BTC_alt: string;
	ETH: string;
};

export const NETWORKS = {
	// https://chainlist.org/
	43114: {
		chainId: "0xA86A",
		name: "Avalanche C-Chain",
		nativeCurrency: { // === ``Coin.name``
			name: "AVAX",
			decimals: ethers.BigNumber.from(18),
			denominator: ethers.BigNumber.from(10).pow(18)
		},
		rpcURL: "https://api.avax.network/ext/bc/C/rpc",
		blockExplorerURL: "https://snowtrace.io",
		blockExplorerForTokenURL: "https://avascan.info/blockchain/c/token/",
		base0xURL_v1: "https://avalanche.api.0x.org",
		base0xURL_v2: "https://api.0x.org",
		ZeroXExchangeProxy: "0xDef1C0ded9bec7F1a1670819833240f027b25EfF", // Unconfirmed on chains other than Avalanche C-Chain
		USDC: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
		Coin: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		WCoin: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
		BTC: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
		BTC_alt: "0x50b7545627a5162F82A992c33b87aDc75187B218",
		ETH: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB"
	} as INetwork,
	42161: {
		chainId: "0xA4B1",
		name: "Arbitrum One",
		nativeCurrency: { // === ``Coin.name``
			name: "ETH",
			decimals: ethers.BigNumber.from(18),
			denominator: ethers.BigNumber.from(10).pow(18)
		},
		rpcURL: "https://arbitrum.llamarpc.com",
		blockExplorerURL: "https://arbiscan.io",
		blockExplorerForTokenURL: "https://arbiscan.io/token/",
		base0xURL_v1: "https://arbitrum.api.0x.org",
		base0xURL_v2: "https://api.0x.org",
		ZeroXExchangeProxy: "0xDef1C0ded9bec7F1a1670819833240f027b25EfF", // Source: https://0x.org/docs/0x-swap-api/api-references/overview#addresses-by-network
		USDC: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
		Coin: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		WCoin: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
		BTC: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
		BTC_alt: "",
		ETH: ""
	} as INetwork,
	31337: {
		chainId: "0x7A69",
		name: "Fork",
		nativeCurrency: { // === ``Coin.name``
			name: "AVAX",
			decimals: ethers.BigNumber.from(18),
			denominator: ethers.BigNumber.from(10).pow(18)
		},
		rpcURL: "https://api.avax.network/ext/bc/C/rpc",
		blockExplorerURL: "https://snowtrace.io",
		blockExplorerForTokenURL: "https://avascan.info/blockchain/c/token/",
		base0xURL_v1: "https://avalanche.api.0x.org",
		base0xURL_v2: "https://avalanche.api.0x.org",
		ZeroXExchangeProxy: "0xDef1C0ded9bec7F1a1670819833240f027b25EfF", // Unconfirmed on chains other than Avalanche C-Chain
		USDC: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
		Coin: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		WCoin: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
		BTC: "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
		BTC_alt: "0x50b7545627a5162F82A992c33b87aDc75187B218",
		ETH: "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB"
	} as INetwork,
};

export const BASE_WIKI_URL = "https://wiki.trendespresso.com";
export const BASE_LEARN_MORE_URL = BASE_WIKI_URL + "/strategies/";

export const defaultNetwork = NETWORKS[43114];

// export const MIN_APP_BLOCKCHAIN_UPDATE_RATE_MS = 3000;
export const MAX_APP_BLOCKCHAIN_UPDATE_RATE_MS = 1000;

export const projectId = "ae66fdbd52636cf7dc9ebe1d91321ba2";

// type NetworkProfile = {
// 	explorer: string;
// 	label: string;
// 	nativeCurrency: {
// 		name: string;
// 		symbol: string;
// 		decimals: 18;
// 	};
// 	rpcUrl: string;
// };

// export const CHAIN_INFO: { [key: number]: NetworkProfile; } = {
// 	[AVAX_MAIN_NET]: {
// 		explorer: "https://snowtrace.io/",
// 		label: "Avalanche (C-Chain)",
// 		nativeCurrency: { name: "AVAX", symbol: "AVAX", decimals: 18 },
// 		rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
// 	},
// 	[AVAX_FUJI_NET]: {
// 		explorer: "https://testnet.snowtrace.io/",
// 		label: "Avalanche (C-Chain) (TEST)",
// 		nativeCurrency: { name: "AVAX", symbol: "AVAX", decimals: 18 },
// 		rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
// 	}
// };


// -> MUST be a youtube.com/embed/ link !!
// Info: https://sites.edb.utexas.edu/wordpress/blog/embedding-a-youtube-video-with-start-and-stop-time/
export const TUTORIAL_URL = "https://www.youtube.com/embed/NwZ0DHgJ31I?si=8fZq_-TrziDLp8XH&start=210&t=210"; // TODO Make a tutorial!!


// Public URL
// export const SUPABASE_PROJECT_URL = "https://slfawdnjhuwzdqkuvlig.supabase.co"; // DEV
export const SUPABASE_PROJECT_URL = "https://ybdsdjcrwtspzuxuvahf.supabase.co"; // PROD
// Public API key
// export const SUPABASE_PROJECT_API_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNsZmF3ZG5qaHV3emRxa3V2bGlnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTA0MzI4MzYsImV4cCI6MjAyNjAwODgzNn0.aURHozK0gHmQpR7eKI431285RP_D_W32oWICeLqh3qw"; // DEV
export const SUPABASE_PROJECT_API_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InliZHNkamNyd3RzcHp1eHV2YWhmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTA0MjczNDQsImV4cCI6MjAyNjAwMzM0NH0.BhoCTLQVWFVntT314SJFkXrQS4nz1vt2gVpeGPQmRPw"; // PROD


export const STRATEGY_TRADERS = [
	{ traders: [""] }, // 0 strategy
	{ traders: [""] }, // 1 strategy
	{ traders: ["0xF6F3BF7741D60bEFCab4aC28C639B3C98889864B"], defaultTradePageStrategyIndex: 6 }, // 2 strategy
	{ traders: ["0x8f72f253088644bFd856E0f34f4d30702A7799eF"], defaultTradePageStrategyIndex: 0 }, // 3 strategy
	{ traders: ["0xF6F3BF7741D60bEFCab4aC28C639B3C98889864B"], defaultTradePageStrategyIndex: 6 }, // 4 strategy
	{ traders: [""] }, // 5 strategy
];


// export const TRADING_ENGINE_BASE_URL = "http://localhost:3100"; // Debug local server
export const TRADING_ENGINE_BASE_URL = "https://mighty-grouper-splendid.ngrok-free.app"; // REAL one is Mighty Grouper Splendid