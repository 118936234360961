import React from "react";
import { DepositWithdraw } from "src/pages/strategy/dialogs/DepositWithdraw";
import { Footer } from "src/pages/Footer";
import { Header } from "src/pages/Header";
import { PageTemplate } from "src/pages/PageTemplate";
import { Sidebar } from "src/pages/strategy/Sidebar";
import { AcceptedFCAContext, DialogContext, WalletContext } from "src/global/contexts";
import { useNavigate } from "react-router-dom";
import { Control } from "src/pages/swap/content/Control";

// SWAP PAGE
export function Page() {
  const { acceptedFcaNotice } = React.useContext(AcceptedFCAContext);
  const { wallet } = React.useContext(WalletContext);
  const { dialogState } = React.useContext(DialogContext);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (acceptedFcaNotice === false) {
      navigate("/");
    }
  }, [acceptedFcaNotice, navigate]);

  return (
    <div className={`etx-app-wrapper ${dialogState === "OPEN" ? "overlay-show" : null}`}>
      <Header
        title="Swap"
        description={
          <a href="https://0x.org/" target="_blank" rel="noreferrer">
            <img
              src="https://assets.website-files.com/640bf70a17d12b42d97a052b/641a220f0ec6db351732ce83_brand-powered-by-0x-white.svg"
              alt="Powered by 0x"
              style={{ alignContent: "center", width: "150px", height: "30px", filter: "brightness(50%)" }}
            />
          </a>
        }
      />
      <PageTemplate
        activeRoute="/swap"
        content={
          <div className="etx-main">
            <div className="etx-main__body">
              {wallet.connectionState === "CONNECTED" ? <Control /> : <></>}
            </div>
          </div>
        }
        sidebar={<Sidebar />}
      />
      <Footer />
      {dialogState === "OPEN" ? <DepositWithdraw /> : <></>}
    </div>
  );
}
