export const consoleLogOnLoad =
	`--- ON LOAD ---
Libraries:
<library> deployed to: <address>

Contracts:
<contract> deployed to: <address>`;


/* 31 MAY 2024

--- printDeployedAddresses() start ---
LibController deployed to: 0x2b781134106c17a2ede092C873BE49159E78c820
Lib deployed to: 0xE08142eA7b9C55B19EC1bdD6614a8344c75dFC04
LibWithdrawNow deployed to: 0x9036b98857984076AfCb5ee67F42aEB7A957a454
LibTrade deployed to: 0x9aA200fE77C706072C45F2CFAFfD542739bFEF04
LibDirectWithdraw deployed to: 0xdf35C077a458e9780DC4B5Cb1Ea21b900168B7A9
LibPooledFund deployed to: 0xae0858Fa4c2B8eC37045F61d5B2f78af64164b6f
LibPooledFundUpdateBalances deployed to: 0x753c9A0862C656E206F80833dd5E60FcfA456b8a

Controller deployed to: 0x7E7EE0FFd004b16DC3a5F9A1d9862575D0b033Ae
Frontend deployed to: 0x1B1537528dB49AaA6FeA148bD1A46EB614C4aA4E
DepositWithdraw deployed to: 0x5E4Ffe5B7c2a6E04DA1fA1Ce46C2AC9193897C98
AccountFactory deployed to: 0x0aB1b52339F33250c5F674Dd90932627560cE45e
TradingSignature deployed to: 0x59D5AbF30aB6B164a9BF592f25F00309a699c10d
PooledFund deployed to: 0xb68F681b814384a6f53204e074D5ac9020128856
TokenHolder deployed to: 0x2D0c136451D0AF0Dc81fff9aE421CE7D7950db49
TE deployed to: 0x77171464ae48a2C96B3b7E513e9EEc5843207E7E
USDC deployed to: 0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E
BTC.b deployed to: 0x152b9d0FdC40C096757F570A51E494bd4b943E50
WBTC.e deployed to: 0x50b7545627a5162f82a992c33b87adc75187b218
WETH.e deployed to: 0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB
TraderJoe deployed to: 0x60aE616a2155Ee3d9A68541Ba4544862310933d4

Deployer wallet: 0x6C7Ae852D959A65D149f5Af5e5A4C75a2a62731e

### Script finished ###
*/
