import React from "react";
import { Link } from "react-router-dom";
import { WalletContext } from "src/global/contexts";

// const teLogoNeg: string = require("src/assets/img/logo_trendespresso_neg.svg").default;
const IMG_TE_Logo_Colour: string = require("src/assets/img/TE_Logo_Colour.svg").default;
// const teLogoColoured: string = require("src/assets/img/logo_te_colour_naked.svg").default;

interface IProps {
  activeRoute: "/" | "/swap" | "/memberships" | "/trade" | "/fca";
}

export function Navigation(props: IProps) {
  const { wallet } = React.useContext(WalletContext);

  return (
    <aside className="etx-body__aside">
      <div className="SidebarNavigation">
        <div className="SidebarNavigation__logo">
          <a href="https://wiki.trendespresso.com" target="_blank" rel="noopener">
            {/* transition: "all 0.4s var(--transition-timing)", filter: "url(../../assets/img/logo_te_colour_naked.svg)" */}
            {/* <img src={ IMG_TE_Logo_Colour } className="te-logo-animation" style={{ width: "65px", marginBottom: "-20px", marginTop: "-20px" }} /> */}
            <img src={IMG_TE_Logo_Colour} className="te-logo-animation" style={{ width: "65px" }} />
          </a>
        </div>

        <div className="SidebarNavigation__links">
          <nav>
            <ul>
              <li>
                <Link
                  className={props.activeRoute === "/" ? "active" : "inactive"}
                  title="Trendespresso"
                  to="/"
                >
                  <i className="fal fa-chart-mixed"></i>
                </Link>
                <span className="SidebarNavigationLabel">Trendespresso</span>
              </li>
              <>
                {wallet.connectionState === "CONNECTED" ? (
                  <>
                    <li>
                      <Link
                        className={props.activeRoute === "/swap" ? "active" : "inactive"}
                        title="Swap"
                        to="/swap"
                      >
                        <i className="fal fa-arrows-repeat"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={props.activeRoute === "/memberships" ? "active" : "inactive"}
                        title="Memberships"
                        to="/memberships"
                      >
                        <i className="fal fa-address-card"></i>
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <a
                        className={`${props.activeRoute === "/swap" ? "active" : "inactive"} disabled`}
                        title="Swap"
                      >
                        <i className="fal fa-arrows-repeat"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className={`${props.activeRoute === "/memberships" ? "active" : "inactive"} disabled`}
                        title="Memberships"
                      >
                        <i className="fal fa-address-card"></i>
                      </a>
                    </li>
                  </>

                )}
              </>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
}
