import React from "react";
import { IPctPositionsObj } from "src/global/types";
import { BigNumber, fixSolidityStruct, getTradeStatus } from "src/global/utils";
import { TE_CONSTANTS } from "src/global/constants";
import { IBalances } from "src/pages/trade/Sidebar";
import { ethers } from "ethers";
import { SystemSummaryContext } from "src/global/contexts";

interface IProps {
  fieldName: string;
  balances?: IBalances;
  depositWithdrawFunctionsForStrategy?: ethers.Contract | undefined;
  setTradeTypeSelected?: (a: "long" | "short") => void;
}

const defaultProps: IProps = {
  fieldName: "{FIELD_NAME}",
  setTradeTypeSelected: (a) => console.warn("setTradeTypeSelected not bound in TradeStatus"),
};

export function TradeStatus(props = defaultProps) {
  const { SystemSummary } = React.useContext(SystemSummaryContext);

  const [tabSelected, setTabSelected] = React.useState(false);

  const _getPctPositions = (balances: IBalances) => {
    const pctCash = balances.cashAvailable / balances.totalCashValue;
    const pctLong = (1 - pctCash) * balances.assetLong / (balances.assetLong + balances.assetDebt);
    const pctShort = (1 - pctCash) * balances.assetDebt / (balances.assetLong + balances.assetDebt);

    const pctPositions: IPctPositionsObj = {
      pctCash: BigNumber(Math.round(pctCash * 100)).mul(TE_CONSTANTS.PCT_ONE_HUNDRED).div(100),
      pctLong: BigNumber(Math.round(pctLong * 100)).mul(TE_CONSTANTS.PCT_ONE_HUNDRED).div(100),
      pctShort: BigNumber(Math.round(pctShort * 100)).mul(TE_CONSTANTS.PCT_ONE_HUNDRED).div(100),
      pctReserve: BigNumber(0),
      totalCashValue: BigNumber(balances.totalCashValue)
    };

    return pctPositions;
  };

  const _getIsLong = () => SystemSummary.currentContract.pctPositions.pctLong.gt(0);
  const _getIsShort = () => SystemSummary.currentContract.pctPositions.pctShort.gt(0);
  const _getTradeStatus = () => getTradeStatus(SystemSummary.currentContract.pctPositions);

  const [isLong, setIsLong] = React.useState(_getIsLong());
  const [isShort, setIsShort] = React.useState(_getIsShort());
  const [tradeStatus, setTradeStatus] = React.useState(_getTradeStatus());

  // Has the side-effect of changing over time based on value of the current trade
  React.useEffect(() => {
    if (
      !props.balances
      || props.balances.totalCashValue === 0
      || (props.balances.assetLong + props.balances.assetDebt) === 0
      || Object.values(props.balances).find(val => val === undefined)
    ) {
      return;
    }

    const pctPositions = _getPctPositions(props.balances);

    setIsLong(pctPositions.pctLong.gt(0));
    setIsShort(pctPositions.pctLong.gt(0));
    setTradeStatus(getTradeStatus(pctPositions));
  }, [props.balances]);

  React.useEffect(() => {
    if (props.depositWithdrawFunctionsForStrategy?.functions === undefined) {
      return;
    }

    const account = SystemSummary.Account.tradingEngineInfo.addr;
    if (!account || account === ethers.constants.AddressZero) {
      return;
    }

    props.depositWithdrawFunctionsForStrategy.functions.getPctPositionsOf(account)
      .then((pctPositions: IPctPositionsObj | any[]) => {
        // Sometimes Web3 will nest the returns sometimes ... no idea why
        if (pctPositions instanceof Array) {
          pctPositions = pctPositions[0];
        }
        pctPositions = fixSolidityStruct(pctPositions) as IPctPositionsObj;
        if (!pctPositions.hasOwnProperty("pctLong") || !pctPositions.hasOwnProperty("pctShort")) {
          throw new Error("`pctPositionsFixed` returned from OUTCOME_WATCHER's `account` is invalid!");
        }

        // Due to state not saving until the next Render, we make `const`s
        // See "Pitfall"
        // -> Documentation: https://react.dev/reference/react/useState#usage#pitfall
        const tradeStatus = getTradeStatus(pctPositions);
        const long = pctPositions.pctLong.gt(0);
        const short = pctPositions.pctShort.gt(0);

        setTradeStatus(tradeStatus);
        setIsLong(long);
        setIsShort(short);

        if (!tabSelected) setTabOnLoad(pctPositions, short);
      });
  });

  const setTabOnLoad = (pctPositions: IPctPositionsObj, short: boolean) => {
    if (props.setTradeTypeSelected) {
      props.setTradeTypeSelected(
        short && pctPositions.pctShort.gte(pctPositions.pctLong)
          ? "short"
          : "long"
      );
    }
    setTimeout(() => {
      if (!tabSelected && props.setTradeTypeSelected) {
        props.setTradeTypeSelected(
          short && pctPositions.pctShort.gte(pctPositions.pctLong)
            ? "short"
            : "long"
        );
      }
    }, 500);
    setTabSelected(true);
  };

  return (
    <div className="etx-card__item Card__item pv-50">
      <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
        <div className="etx-flex__item Flex__item shrink-0">
          <div className="etx-text Text">
            <p className="font-label">{props.fieldName}</p>
          </div>
        </div>

        <div className="etx-flex__item Flex__item grow-1">
          <div className="etx-text Text a-right">
            <p className="font-size-large font-weight-medium" id="TradePage_tradeStatus">
              {
                isLong && isShort
                  ? <span style={{ fontSize: "0.8em" }}>{tradeStatus}</span>
                  : tradeStatus
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}