import React from "react";
import { BigNumber, getPriceFromCoinbase, limitDecimals, logicallyLimitDecimals, toReadableNumber, toSolidityNumber } from "src/global/utils";
import { ContractsManagerContext, WalletContext, SystemSummaryContext, FunctionsContext, StrategyContext, DialogContext, NetworkContext } from "src/global/contexts";
import { ethers } from "ethers";
import { TE_CONSTANTS, TRADING_ENGINE_BASE_URL } from "src/global/constants";
import { DepositWithdrawDialogTab, ISysAccountInfoObj, ISysPooledFundInfoObj, defaultPermit, isRPCError } from "src/global/types";
import { Control } from "src/pages/swap/content/Control";

interface IDepositEstimate {
  futureProfitSharePct: ethers.BigNumber;
  futureBalanceTe: ethers.BigNumber;
  futureBalanceCash: ethers.BigNumber;
}

interface IWithdrawEstimate {
  netProfitShare: ethers.BigNumber;
  futureProfitSharePct: ethers.BigNumber;
  futureBalanceTe: ethers.BigNumber;
  futureBalanceCash: ethers.BigNumber;
}

export function DepositWithdraw() {
  const { contractManager } = React.useContext(ContractsManagerContext);
  const { selectedStrategy } = React.useContext(StrategyContext);
  const { SystemSummary } = React.useContext(SystemSummaryContext);
  const { wallet } = React.useContext(WalletContext);
  const { dialogTab } = React.useContext(DialogContext);
  const { network } = React.useContext(NetworkContext);
  const {
    tryTx,
    createPermit,
    toggleDepositWithdrawDialog,
    switchDepositWithdrawDialogTab,
    showErrorMessage,
    showDialog,
    setLoader
  } = React.useContext(FunctionsContext);

  const [teAmount, setTeAmount] = React.useState(BigNumber(0));
  const [teAmount_str, setTeAmount_str] = React.useState("");

  const [cashAmount, setCashAmount] = React.useState(BigNumber(0));
  const [cashAmount_str, setCashAmount_str] = React.useState("");

  const [current, setCurrent] = React.useState(BigNumber(0));
  const [pretrade, setPretrade] = React.useState(BigNumber(0));

  const defaultDepositEstimate: IDepositEstimate = {
    futureProfitSharePct: SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.pctProfitShare,
    futureBalanceTe: BigNumber(SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE),
    futureBalanceCash: current
  };

  const defaultWithdrawEstimate = {
    netProfitShare: BigNumber(0),
    futureProfitSharePct: SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.pctProfitShare,
    futureBalanceTe: BigNumber(SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE),
    futureBalanceCash: current
  };

  const [depositEstimate, setDepositEstimate] = React.useState(defaultDepositEstimate);
  const [withdrawEstimate, setWithdrawEstimate] = React.useState(defaultWithdrawEstimate);
  const [infoExpanded, setInfoExpanded] = React.useState(false);

  const [cashBalanceDeposit, setCashBalanceDeposit] = React.useState(0);
  const [cashBalanceWithdraw, setCashBalanceWithdraw] = React.useState(0);
  const [teBalanceDeposit, setTEBalanceDeposit] = React.useState(0);
  const [teBalanceWithdraw, setTEBalanceWithdraw] = React.useState(0);

  React.useEffect(() => {
    if (!contractManager.cash.decimals) {
      console.log("No cash decimals in DepositWithdrawDialog()!");
      return;
    }

    if (!contractManager.cash.denominator) {
      console.log("No cash decimals in DepositWithdrawDialog()!");
      return;
    }

    if (!contractManager.contracts.TE.decimals) {
      console.log("No cash decimals in DepositWithdrawDialog()!");
      return;
    }

    if (!contractManager.contracts.TE.denominator) {
      console.log("No cash decimals in DepositWithdrawDialog()!");
      return;
    }

    setCashBalanceDeposit(limitDecimals(wallet.balances.cash.toNumber() / contractManager.cash.denominator.toNumber()));
    setCashBalanceWithdraw(limitDecimals(current.toNumber() / contractManager.cash.denominator.toNumber()));

    setTEBalanceDeposit(limitDecimals(wallet.balances.TE.toNumber() / contractManager.contracts.TE.denominator.toNumber()));
    setTEBalanceWithdraw(limitDecimals(SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE / contractManager.contracts.TE.denominator.toNumber()));
  }, [
    SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE,
    contractManager.contracts.TE.decimals,
    contractManager.contracts.TE.denominator,
    contractManager.cash.decimals,
    contractManager.cash.denominator,
    current,
    wallet.balances.TE,
    wallet.balances.cash
  ]);

  React.useEffect(() => {
    let _current = BigNumber(0);
    let _pretrade = BigNumber(0);

    if (selectedStrategy.isPooledFund && "memberBalance" in SystemSummary.currentContract) {
      const currentContract = (SystemSummary.currentContract as ISysPooledFundInfoObj);
      _current = BigNumber(currentContract.memberBalance.current);
      _pretrade = BigNumber(currentContract.memberBalance.pretrade);
    }
    else {
      const currentContract = (SystemSummary.currentContract as ISysAccountInfoObj);
      _current = BigNumber(currentContract.contractBalance.current);
      _pretrade = BigNumber(currentContract.contractBalance.pretrade);
    }

    setCurrent(_current);
    setPretrade(_pretrade);

    setDepositEstimate({ ...defaultDepositEstimate, futureBalanceCash: _current });
    setWithdrawEstimate({ ...defaultWithdrawEstimate, futureBalanceCash: _current });
  }, [SystemSummary.currentContract, selectedStrategy.isPooledFund]);

  function onTEAmountSet(e: React.ChangeEvent<HTMLInputElement>) {
    const val: string = e.target.value.replace(/[^0-9.]/g, "");

    if (val.includes(".") && val.split(".")[1].length > contractManager.contracts.TE.decimals.toNumber()) {
      return;
    }

    const newTeAmount = toSolidityNumber(val, contractManager.contracts.TE.decimals);
    setTeAmount(newTeAmount);
    setTeAmount_str(val);

    if (dialogTab === "DEPOSIT") {
      getDepositEstimate({ newTeAmount });
    }
    else {
      getWithdrawEstimate({ newTeAmount });
    }
  };

  function onCashAmountSet(e: React.ChangeEvent<HTMLInputElement>) {
    const val: string = e.target.value.replace(/[^0-9.]/g, "");

    if (val.includes(".") && val.split(".")[1].length > contractManager.cash.decimals.toNumber()) {
      return;
    }

    const newCashAmount = toSolidityNumber(val, contractManager.cash.decimals);
    setCashAmount(newCashAmount);
    setCashAmount_str(val);

    if (dialogTab === "DEPOSIT") {
      getDepositEstimate({ newCashAmount });
    }
    else {
      getWithdrawEstimate({ newCashAmount });
    }
  }

  function getDepositEstimate({ newCashAmount = cashAmount, newTeAmount = teAmount }) {
    const newDepositEstimate: IDepositEstimate = {
      futureProfitSharePct: SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.pctProfitShare,
      futureBalanceTe: BigNumber(SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE).add(newTeAmount),
      futureBalanceCash: current.add(newCashAmount)
    };

    setDepositEstimate(newDepositEstimate);
  };

  function changeTab(activeTab: DepositWithdrawDialogTab) {
    switchDepositWithdrawDialogTab(activeTab);

    setCashAmount(BigNumber(0));
    setCashAmount_str("");

    setTeAmount(BigNumber(0));
    setTeAmount_str("");

    setDepositEstimate(defaultDepositEstimate);
    setWithdrawEstimate(defaultWithdrawEstimate);
  };

  function setMaxTEAmount() {
    if (dialogTab === "DEPOSIT") {
      setTeAmount(wallet.balances.TE);
      setTeAmount_str(toReadableNumber(wallet.balances.TE, contractManager.contracts.TE.decimals));

      getDepositEstimate({ newTeAmount: wallet.balances.TE });
    }
    else {
      setTeAmount(BigNumber(SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE));
      setTeAmount_str(toReadableNumber(BigNumber(SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE), contractManager.contracts.TE.decimals));

      getWithdrawEstimate({ newTeAmount: BigNumber(SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE) });
    }
  };

  function getWithdrawEstimate({ newCashAmount = cashAmount, newTeAmount = teAmount }) {
    // If balances.current > balances.pretrade && amountcash > balances.pretrade, then (amountcash - balances.pretrade) * accountinfo.pctnetprofitshare / onehundredpct, else 0
    const netProfitShare = current.gt(pretrade) && cashAmount.gt(pretrade)
      ? newCashAmount.sub(pretrade).mul(SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.pctProfitShare).div(TE_CONSTANTS.PCT_ONE_HUNDRED)
      : BigNumber(0);

    const newWithdrawEstimate: IWithdrawEstimate = {
      netProfitShare,
      futureProfitSharePct: SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.pctProfitShare,
      futureBalanceTe: BigNumber(SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE).sub(newTeAmount),
      futureBalanceCash: current.sub(newCashAmount)
    };

    setWithdrawEstimate(newWithdrawEstimate);
  };

  function setMaxCashAmount() {
    if (dialogTab === "DEPOSIT") {
      setCashAmount(wallet.balances.cash);
      setCashAmount_str(toReadableNumber(wallet.balances.cash, contractManager.cash.decimals));

      getDepositEstimate({ newCashAmount: wallet.balances.cash });
    }
    else {
      setCashAmount(current);
      setCashAmount_str(toReadableNumber(current, contractManager.cash.decimals));

      getWithdrawEstimate({ newCashAmount: current });
    }
  };

  async function deposit() {
    // Approval Tx pops up if Allowance < Deposit value
    if (cashAmount.eq(0) && teAmount.eq(0)) {
      console.log("Deposit of 0 ignored");
      return;
    }

    let minAvaxAmount: ethers.BigNumber;
    let gaslessDialogOpen = false;
    let gaslessSwapOccurred = false;
    let errorOccurred = false;

    if (!wallet.provider) {
      throw new Error("wallet.provider is undefined in deposit()!");
    }
    const balanceAvax = await wallet.provider.getBalance(wallet.address);

    const insufficientGas = async () => {
      if (!contractManager.contracts.GaslessDeposit.functions) {
        throw new Error("GaslessDeposit contract is undefined in insufficientGas()!");
      }

      // TODO Could make this more modular for each blockchain
      minAvaxAmount = await contractManager.contracts.GaslessDeposit.functions.defaultAmountAVAX();

      // TODO Make this a prompt where the user must opt-in !!!!
      // TODO Make this a prompt where the user must opt-in !!!!
      // TODO Make this a prompt where the user must opt-in !!!!
      return balanceAvax.lt(minAvaxAmount.div(2));
    };

    const action = async () => {
      if (!contractManager.contracts.GaslessDeposit.functions) {
        throw new Error("GaslessDeposit contract is undefined in action()!");
      }
      if (!minAvaxAmount) {
        throw new Error("``minAvaxAmount`` is undefined in action()!");
      }
      const amountUsdcToSend = await contractManager.contracts.GaslessDeposit.functions.convertAmountAVAXToAmountUSDC_withFee(minAvaxAmount);
      try {
        const swapUsdcForAvaxPermit = await createPermit(
          contractManager.contracts.USDC,
          contractManager.contracts.GaslessDeposit.address,
          amountUsdcToSend
        );

        try {
          setLoader(true);
          // setTimeout(() => setLoader(true), 500);

          const res = await fetch(`${TRADING_ENGINE_BASE_URL}/gaslessDeposit`, {
            method: "POST",
            body: JSON.stringify(swapUsdcForAvaxPermit),
            headers: { "Content-type": "application/json; charset=UTF-8" }
          });

          if (res.status === 200) {
            setTimeout(() => {
              setLoader(false);
              setTimeout(() => {
                showDialog(
                  "SUCCESS",
                  "Gasless deposit request submitted. Signature was successfully verified. Trading Engine is processing a small gasless swap of USDC to AVAX for you.",
                  [{
                    text: "OK",
                    type: "CONFIRM",
                    action: () => { gaslessDialogOpen = false; gaslessSwapOccurred = true; }
                  }]
                );
              }, 500);
            }, 2000);
          }
          else {
            setLoader(false);
            // throwTradeErrorOnInvalidMessage(verifyTradeSignature.deadline);
            showDialog(
              "Error",
              "GASLESS DEPOSIT ERROR: Do you have sufficient USDC to pay for a gasless swap? Please try obtaining more USDC and Depositing again.",
              [{
                text: "OK",
                type: "CONFIRM",
                action: () => { gaslessDialogOpen = false; gaslessSwapOccurred = false; }
              }]
            );
          }
        }
        catch (error) {
          setLoader(false);
          showDialog(
            "Network Error",
            `The URL "${TRADING_ENGINE_BASE_URL}/gaslessDeposit" is inaccessible. Please alert contact@trendespresso.com`,
            [{
              text: "OK",
              type: "CONFIRM",
              action: () => { gaslessDialogOpen = false; gaslessSwapOccurred = false; }
            }]
          );
          console.log(error);
        }
      }
      catch (error) {
        showErrorMessage(error as any);
        errorOccurred = true;
        setTimeout(() => { gaslessDialogOpen = false; gaslessSwapOccurred = false; }, 5000);
      }
    };

    console.log("got to here in deposit ...");

    try {
      if (await insufficientGas()) {
        gaslessDialogOpen = true;
        showDialog(
          "Gasless Deposit",
          <div>
            {/* <hr /> */}
            {/* <br /> */}
            Your wallet has insufficient {contractManager.contracts.Coin.name} to submit transactions.{" "}
            Transactions on the blockchain cost gas which are charged in {contractManager.contracts.Coin.name} on {network.name}.{" "}
            <a
              href="https://youtu.be/3ehaSqwUZ0s"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to learn about gas.
            </a>
            <br />
            {/* <br />
            <hr /> */}
            <br />
            Would you like to swap a small amount of USDC to AVAX to enable you to process Deposits and Withdrawals?
            <br />
            <br />
            <b className="font-weight-bold">
              {/* bold, black, strong */}
              This will cost approximately {logicallyLimitDecimals((await getPriceFromCoinbase("AVAX", "USD")) * 0.10 * 1.25)} USDC to obtain ≈ 0.1 AVAX.
            </b>
          </div>,
          [{
            text: "Yes",
            type: "CONFIRM",
            action
          },
          {
            text: "No",
            type: "CANCEL",
            action: () => { gaslessDialogOpen = false; gaslessSwapOccurred = false; }
          }]
        );
      }
    }
    catch (error) {
      console.warn(error);
    }

    // Wait for the dialog to complete via new Promise's of 250 ms each
    while (gaslessDialogOpen) {
      await new Promise(res => setTimeout(res, 250));
      if (errorOccurred) {
        return;
      }
    }

    if (gaslessSwapOccurred) {
      setLoader(true);
    }

    // TODO Make this into a ``.subscribe`` call instead
    let count = 0;
    while (gaslessSwapOccurred) {
      await new Promise(res => setTimeout(res, 250));
      gaslessSwapOccurred = balanceAvax.eq(await wallet.provider.getBalance(wallet.address));
      count++;
      if (count >= 120 || !gaslessSwapOccurred) {
        setLoader(false);
        break;
      }
    }


    // console.log("BigNumber(SystemSummary.currentContract.subscription.paidThru", BigNumber(SystemSummary.currentContract.subscription.paidThru), SystemSummary.currentContract.subscription.paidThru);
    // console.log("Math.floor((Date.now() / 1000) + 3600)", Math.floor((Date.now() / 1000) + 3600));
    // console.log("BigNumber(SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE)", BigNumber(SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE), SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE);
    // console.log("BigNumber(SystemSummary.currentContract.subscription.feePerMonth)", BigNumber(SystemSummary.currentContract.subscription.feePerMonth), SystemSummary.currentContract.subscription.feePerMonth);
    // console.log(" wallet.balances.TE", wallet.balances.TE);
    // console.log(" SystemSummary.currentContract.subscription.feePerMonth", SystemSummary.currentContract.subscription.feePerMonth);
    // console.log(wallet.balances.TE < SystemSummary.currentContract.subscription.feePerMonth);
    // console.log(wallet.balances.TE.lt(SystemSummary.currentContract.subscription.feePerMonth));


    if (
      // If there's an expired subscription AND insufficient TE deposited AND insufficient TE in your wallet ...
      BigNumber(SystemSummary.currentContract.subscription.paidThru)
        .lte(Math.floor((Date.now() / 1000) + 3600 /* 1 hour */))
      &&
      BigNumber(SystemSummary.Controller.ownerInfoAfterSubscriptionPayments.balanceTE)
        .lt(BigNumber(SystemSummary.currentContract.subscription.feePerMonth))
      &&
      wallet.balances.TE.lt(SystemSummary.currentContract.subscription.feePerMonth)
    ) {
      // Then prompt the user to swap for some TE.
      showDialog(
        <div>
          <p className="font-h3" style={{ textAlign: "center" }}>Swap for <span style={{ fontSize: "0.95em" }}>Ŧ</span>E</p>
        </div>,
        <div className="SwapControl">
          <hr className="dotted swapPopup" />
          <br />
          <div className="swapPopup">
            <p>
              You have insufficient <span style={{ fontSize: "0.95em" }}>Ŧ</span>E to pay for a monthly subscription
              to this strategy. Swap for <span style={{ fontSize: "0.95em" }}>Ŧ</span>E below or{" "}
              <a
                href="/swap"
              >
                visit the Swap page
              </a>
              .
            </p>
            <p>
              <b>Monthly Subscription Fee:</b>
              {" "}
              {limitDecimals(
                SystemSummary.currentContract.subscription.feePerMonth.toNumber()
                / contractManager.contracts.TE.denominator.toNumber()
              )}
              {" "}
              <span style={{ fontSize: "0.95em" }}>Ŧ</span>E
            </p>
          </div>
          <br />
          <div id="swapControlForTEOnDeposit">
            <Control />
          </div>
          <br />
          <div className="swapPopup" style={{ textAlign: "center" }}>
            <p>When you are finished, simply click OK and continue with your Deposit!</p>
          </div>
        </div>
      );
      return;
    }

    const contract =
      selectedStrategy.isPooledFund
        ? contractManager.contracts.PooledFund
        : contractManager.contracts.DepositWithdraw;

    if (!contract.address) {
      throw new Error("No contract address in deposit()!");
    };

    let cash_permit;
    let te_permit;

    try {
      cash_permit = cashAmount.gt(0) ? await createPermit(contractManager.contracts.USDC, contract.address, cashAmount) : defaultPermit;
      te_permit = teAmount.gt(0) ? await createPermit(contractManager.contracts.TE, contract.address, teAmount) : defaultPermit;
    }
    catch (error) {
      if (isRPCError(error)) {
        showErrorMessage(error);
      }

      console.error(error);
      return;
    }

    const amounts = [cashAmount, teAmount];
    const deadlines = [cash_permit.deadline, te_permit.deadline];
    const v = [cash_permit.v, te_permit.v];
    const r = [cash_permit.r, te_permit.r];
    const s = [cash_permit.s, te_permit.s];

    console.log("Got to the end of permit during `deposit()");
    console.log([amounts, deadlines, v, r, s]);

    if (!contract.functions) {
      throw new Error("No contract functions in deposit()!");
    };

    tryTx(contract.functions.depositWithPermit, [amounts, deadlines, v, r, s]);
  }

  async function withdraw() {
    if (!SystemSummary.isRegistered) {
      return;
    }

    if (cashAmount.eq(0) && teAmount.eq(0)) {
      console.log("Withdraw of 0 ignored");
      return;
    }

    const contract = selectedStrategy.isPooledFund ? contractManager.contracts.PooledFund : contractManager.contracts.DepositWithdraw;

    if (!contract.functions) {
      throw new Error("No contract functions in withdraw()!");
    };

    tryTx(contract.functions.withdraw, [cashAmount, teAmount]);
  }

  async function withdrawNow() {
    if (!SystemSummary.isRegistered) {
      return;
    }

    if (cashAmount.eq(0) && teAmount.eq(0)) {
      console.log("WithdrawNow of 0 ignored");
      return;
    }

    // TODO: finish this
    const isLong = SystemSummary.currentContract.tradingEngineInfo.tradeStatus[2].toUpperCase() === "F";
    const isShort = ["F", "A"].includes(SystemSummary.currentContract.tradingEngineInfo.tradeStatus[3].toUpperCase());

    console.log("┳━┳ ヽ(ಠل͜ಠ)ﾉ");
    console.log("isLong? ", isLong);
    console.log("isShort?", isShort);
    console.log("(┛ಠ_ಠ)┛彡┻━┻");

    if (!isLong && !isShort) {
      // Reroute to normal withdraw ...
    }

    console.log(showErrorMessage);
    console.log(selectedStrategy.isPooledFund);

    if (selectedStrategy.isPooledFund) {
      showErrorMessage({
        message: "WITHDRAW NOW ERROR: Invalid action. Pooled funds do not offer immediate withdrawals. If you see this message, please let us know at contact@trendespresso.com"
      });
      return;
    }





    // // TODO: update this
    // // NOTE: SystemSummary.currentContract == ISysAccountInfoObj OR ISysPooledFundInfoObj;

    // /*
    //   struct TradeInfoObj {
    //     address Account;
    //     int8 order; // submit -1 --> shortAmounts[AmountCashToDeposit, AmountAssetToBorrow]
    //     uint8 feesOperation; // 0x00 for none; 0x01 (or any other odd) for read+write; 0x02 (or any other even) for read only
    //     uint[tLenTradeShorts] shortAmounts;
    //     uint128[tLenTrade] priceProtection;
    //     bytes swapCallData;
    //   }
    // */
    // const blankTradeInfoObj: ITradeInfoObj = {
    //   Account: SystemSummary.Account,
    //   order: 0,
    //   feesOperation: 1, // !!! CHANGE TO `1` for production
    //   shortAmounts: [BigNumber(0), BigNumber(0)],
    //   priceProtection: [BigNumber(0), BigNumber(0), BigNumber(0)],
    //   swapCallData: "0x00"
    // }

    // const tradeInfo = [blankTradeInfoObj, blankTradeInfoObj];

    // let pctToTrade = cashAmount
    //   .mul(TE_CONSTANTS.PCT_ONE_HUNDRED)
    //   .div(current);

    // // Cap it at 100%
    // if (pctToTrade.gt(TE_CONSTANTS.PCT_ONE_HUNDRED)) {
    //   pctToTrade = TE_CONSTANTS.PCT_ONE_HUNDRED;
    // }

    // // Fetch our swapCallData
    // async function getSwapCallData(URL: string) {
    //   const quoteResponse = await fetch(URL, { method: "GET", headers: TE_CONSTANTS.ZeroXAPIKeyHeader });

    //   if (quoteResponse.status !== 200) {
    //     console.log(`\n0x URL used: ${ URL }\n`);
    //     throw await quoteResponse.json();
    //   }

    //   return await quoteResponse.json();
    // }

    // async function getSwapCallData_sell(sellToken: IContract, buyToken: IContract, sellAmount: ethers.BigNumber) {
    //   // TODO Add 0x URL to state
    //   const URL = `https://avalanche.api.0x.org/swap/v1/quote?buyToken=${ buyToken.address }`
    //   + `&sellToken=${ sellToken.address }`
    //   + `&sellAmount=${ sellAmount.toString() }`
    //   // + `&excludedSources=`
    //   // + `&includedSources=`
    //   + `&slippagePercentage=${ TE_CONSTANTS.WITHDRAW_NOW_SLIPPAGE }`;

    //   // console.log(`getSwapCallData_sell: ${ URL }`);

    //   return await getSwapCallData(URL);
    // }

    // async function getSwapCallData_buy(sellToken: IContract, buyToken: IContract, buyAmount: ethers.BigNumberish) {
    //   // TODO Add 0x URL to state
    //   const URL = `https://avalanche.api.0x.org/swap/v1/quote?buyToken=${ buyToken.address }`
    //   + `&sellToken=${ sellToken.address }`
    //   + `&buyAmount=${ buyAmount.toString() }`
    //   // + `&excludedSources=`
    //   // + `&includedSources=`
    //   + `&slippagePercentage=${ TE_CONSTANTS.WITHDRAW_NOW_SLIPPAGE }`;

    //   return await getSwapCallData(URL);
    // }

    // // Start of trade info population
    // let amountCashRaisedFromLong = BigNumber(0);

    // if (isLong) {
    //   const sellAmount = SystemSummary.positions.assetUnsoldLong
    //     .mul(pctToTrade).div(TE_CONSTANTS.PCT_ONE_HUNDRED);

    // 	const quote = await getSwapCallData_sell(contracts.asset, contracts.cash, sellAmount);

    //   tradeInfo[0].order = 254;
    //   tradeInfo[0].swapCallData = quote.data; 
    //   tradeInfo[0].priceProtection = [
    //     quote.grossBuyAmount,
    //     sellAmount,
    //     BigNumber((await getPriceFromCoinbase()) * 0.99).mul(contracts.cash.denominator)
    //   ];

    //   amountCashRaisedFromLong = BigNumber(quote.grossBuyAmount);
    // }

    // if (isShort) {
    //   const aaveAutoDeposit: Boolean = (
    //     (await contracts.Controller.functions.settings())   // 0 x F 0
    //     .pctAutoDepositAllCashIntoAAVE_pctSlippageDiv3)[2]  // 0 1 2 3
    //     .toUpperCase() !== "0";

    //   const buyAmount = (await contracts.DepositWithdraw.functions.getAssetDebt())
    //     .mul(pctToTrade).div(TE_CONSTANTS.PCT_ONE_HUNDRED);

    //   const quote = await getSwapCallData_buy(contracts.cash, contracts.asset, buyAmount);

    //   tradeInfo[1].order = -254;
    //   tradeInfo[1].swapCallData = quote.data;
    //   tradeInfo[1].priceProtection = [
    //     quote.grossSellAmount,
    //     buyAmount,
    //     BigNumber((await getPriceFromCoinbase()) * 0.99).mul(contracts.cash.denominator)
    //   ];
    //   tradeInfo[1].shortAmounts = [
    //     aaveAutoDeposit && !isLong ? cashAmount : cashAmount.sub(amountCashRaisedFromLong),
    //     buyAmount
    //   ];
    // }

    // // function withdrawNow(
    // //   TradeInfoObj[2] memory tradeInfo,
    // //   uint amountCash,
    // //   uint amountTE
    // // );

    // tryTx(contracts.DepositWithdraw.functions.withdrawNow, [flattenObjArray(tradeInfo), cashAmount, teAmount]);
  }

  function getActionButton() {
    switch (dialogTab) {
      case "DEPOSIT":
        return (
          <button
            className="etx-button Button fullwidth border-radius-0 depositColour"
            onClick={deposit}
          >
            <div className="etx-button__icon Button__icon">
              <i className="fal fa-arrow-down-to-arc"></i>
            </div>
            <span className="etx-button__text Button__text">Deposit</span>
          </button>
        );
      case "WITHDRAW":
        return (
          <button
            className="etx-button Button fullwidth border-radius-0 withdrawColour"
            onClick={withdraw}
          >
            <div className="etx-button__icon Button__icon">
              <i className="fal fa-arrow-up-from-arc"></i>
            </div>
            <span className="etx-button__text Button__text">Withdraw</span>
          </button>
        );
      case "WITHDRAW_NOW":
        return (
          <button
            className="etx-button Button fullwidth border-radius-0 withdrawNowColour"
            onClick={withdrawNow}
          >
            <div className="etx-button__icon Button__icon">
              <i className="fal fa-arrow-up-from-arc"></i>
            </div>
            <span className="etx-button__text Button__text">Withdraw Now</span>
          </button>
        );
      default:
        return (
          <button className="etx-button Button fullwidth border-radius-0">
            <div className="etx-button__icon Button__icon">
              <i className="fal fa-question"></i>
            </div>
            <span className="etx-button__text Button__text">?</span>
          </button>
        );
    }
  };

  return (
    <div className="Overlay">
      <div
        className="Overlay__background"
        onClick={() => toggleDepositWithdrawDialog("NONE")}
      ></div>

      <div className="Overlay__content">
        <div className="etx-card Card bg-dark-1-95 border-radius overflow-hidden">
          <div className="etx-card__item Card__item ph-0 pv-0">
            <div className="etx-button-list ButtonList combo card-header">
              <button
                className={
                  "etx-button Button border-radius-0" +
                  (dialogTab === "DEPOSIT" ? " active" : "")
                }
                onClick={() => changeTab("DEPOSIT")}
              >
                <span className="etx-button__text Button__text">Deposit</span>
              </button>

              <button
                className={
                  "etx-button Button border-radius-0" +
                  (dialogTab === "WITHDRAW" ? " active" : "")
                }
                onClick={() => changeTab("WITHDRAW")}
              >
                <span className="etx-button__text Button__text">
                  Withdraw
                </span>
              </button>

              {selectedStrategy.isPooledFund
                // Hide the button since immediate withdrawals aren't an available action.
                ? <></>
                // For individual accounts, show the immediate withdraw button as a red exclamation point.
                : <button
                  className={
                    "etx-button Button border-radius-0 special-withdraw-now withdrawNowColour" +
                    (dialogTab === "WITHDRAW_NOW" ? " withdrawNowColourAlert" : "")
                  }
                  onClick={() => changeTab("WITHDRAW_NOW")}
                >
                  <div className="etx-button__icon Button__icon">
                    <i className="far fa-hexagon-exclamation"></i>
                  </div>
                </button>
              }
            </div>
          </div>

          {dialogTab === "WITHDRAW" && SystemSummary.isInTrade ? (
            <>
              <div className="etx-card__item Card__item tab-s:ph-200">
                <div className="etx-text Text">
                  <p className="font-label">
                    <i
                      className="far fa-info-circle"
                      title="Trade exit refers to the Trading Bot exiting its Long or Short position and returning its Trade status to Holding Cash."
                    ></i>
                    &nbsp; Your withdrawal will get processed on trade exit.
                  </p>
                  <p>
                    <a
                      className="font-weight-light"
                      href="https://wiki.trendespresso.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </p>
                </div>
              </div>
              <div className="etx-card__item Card__item tab-s:ph-200">
                <hr className="dotted"></hr>
              </div>
            </>
          ) : dialogTab === "WITHDRAW_NOW" && SystemSummary.isInTrade ? (
            <>
              <div className="etx-card__item Card__item tab-s:ph-200">
                <div className="etx-text Text">
                  <p className="font-label">
                    <i
                      className="far fa-info-circle"
                      title="NFT holders will pay a lower penalty. The standard penalty is 50% of the current trade's profit."
                    ></i>
                    &nbsp; Withdrawing immediately will incur an additional{" "} profit-sharing penalty.
                  </p>
                  <p>
                    <a
                      className="font-weight-light"
                      href="https://wiki.trendespresso.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </p>
                </div>
              </div>
              <div className="etx-card__item Card__item tab-s:ph-200">
                <hr className="dotted"></hr>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="etx-card__item Card__item tab-s:ph-200">
            <div className="etx-flex Flex c-auto av-center gh-50 gv-25 mob-l:nowrap">
              <div className="etx-flex__item Flex__item c-3 shrink-0">
                <div className="etx-text Text">
                  <p className="font-weight-medium">{contractManager.cash.name}</p>
                </div>
              </div>

              <div className="etx-flex__item Flex__item grow-1">
                <div className="FormField">
                  <div className="FormField__label">
                    {dialogTab !== "DEPOSIT" ? (
                      <div className="FormField__label__item left">
                        <span>Estimated</span>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="FormField__label__item right">
                      <span>Available:</span>
                      <span>
                        {cashBalanceDeposit}
                      </span>
                    </div>
                  </div>

                  <div className="FormField__input">
                    <input
                      type="text"
                      placeholder="0"
                      value={cashAmount_str}
                      onChange={onCashAmountSet}
                    />
                    <button
                      className="FormField__input__button"
                      onClick={setMaxCashAmount}
                    >
                      Max
                    </button>
                  </div>

                  <div className="FormField__label">
                    <div className="FormField__label__item right">
                      <span>Balance:</span>
                      <span>
                        {cashBalanceWithdraw}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="etx-card__item Card__item tab-s:ph-200 pt-0">
            <div className="etx-flex Flex c-auto av-center gh-50 gv-25 mob-l:nowrap">
              <div className="etx-flex__item Flex__item c-3 shrink-0">
                <div className="etx-text Text">
                  <p className="font-weight-medium">TE</p>
                </div>
              </div>

              <div className="etx-flex__item Flex__item grow-1">
                <div className="FormField">
                  <div className="FormField__label">
                    <div className="FormField__label__item left">
                      <span>Estimated</span>
                    </div>
                    <div className="FormField__label__item right">
                      <span>Available:</span>
                      <span>
                        {teBalanceDeposit}
                      </span>
                    </div>
                  </div>

                  <div className="FormField__input">
                    <input
                      type="text"
                      placeholder="0"
                      value={teAmount_str}
                      onChange={onTEAmountSet}
                    />
                    <button
                      className="FormField__input__button"
                      onClick={setMaxTEAmount}
                    >
                      Max
                    </button>
                  </div>

                  <div className="FormField__label">
                    <div className="FormField__label__item right">
                      <span>Balance:</span>
                      <span>
                        {teBalanceWithdraw}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="etx-card__item Card__item ph-0 pt-0 pb-50">
            <hr className="dotted"></hr>
          </div>
          {dialogTab === "DEPOSIT" && infoExpanded === true ? (
            <>
              {/* <div className="etx-card__item Card__item tab-s:ph-200 pv-75">

                <div className="etx-flex Flex c-auto av-center gh-50 gv-0">

                  <div className="etx-flex__item Flex__item shrink-0">
                    <div className="etx-text Text">
                      <p className="font-label">
                        Weekly Upkeep Charge
                        <i
                          className="far fa-info-circle"
                          title="Upfront charge for the remainder of this week."
                        ></i>
                      </p>
                    </div>
                  </div>

                  <div className="etx-flex__item Flex__item grow-1">
                    <div className="etx-text Text a-right">
                      <p className="font-size-regular font-weight-medium">
                        {depositEstimates?.remainingWeeklyUpkeep ? depositEstimates?.remainingWeeklyUpkeep + " TE" : "-"}

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
                <hr className="dotted"></hr>
              </div> */}

              { /* !! ADD FOR BETA !! */}
              {/* <div className="etx-card__item Card__item tab-s:ph-200 pv-75">

                <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                  <div className="etx-flex__item Flex__item shrink-0">
                    <div className="etx-text Text">
                      <p className="font-label">
                        Operation Deposit
                        <i
                          className="far fa-info-circle"
                          title="Held on-deposit. Returned in proportion to your USDC withdrawals."
                        ></i>
                      </p>
                    </div>
                  </div>

                  <div className="etx-flex__item Flex__item grow-1">
                    <div className="etx-text Text a-right">
                      <p className="font-size-regular font-weight-medium">
                        {depositEstimates?.additionalTeOperatingDeposit ? depositEstimates?.additionalTeOperatingDeposit + " TE" : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
                <hr className="dotted"></hr>
              </div>

              <div className="etx-card__item Card__item tab-s:ph-200 pv-75">

                <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                  <div className="etx-flex__item Flex__item shrink-0">
                    <div className="etx-text Text">
                      <p className="font-label">
                        Upkeep Required
                        <i
                          className="far fa-info-circle"
                          title="Held on-deposit. Pays for a future, full additional week of runtime."
                        ></i>
                      </p>
                    </div>
                  </div>

                  <div className="etx-flex__item Flex__item grow-1">
                    <div className="etx-text Text a-right">
                      <p className="font-size-regular font-weight-medium">
                        {depositEstimates?.addlTeNeededToDeposit ? depositEstimates?.addlTeNeededToDeposit + " TE" : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
                <hr className="dotted"></hr>
              </div> */}
              <div className="etx-card__item Card__item tab-s:ph-200 pv-75">

                <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                  <div className="etx-flex__item Flex__item shrink-0">
                    <div className="etx-text Text">
                      <p className="font-label">
                        Future Balance
                        <i
                          className="far fa-info-circle"
                          title="Amount of your TE balance that can pay for future Monthly Upkeep."
                        ></i>
                      </p>
                    </div>
                  </div>

                  <div className="etx-flex__item Flex__item grow-1">
                    <div className="etx-text Text a-right">
                      <p className="font-size-regular font-weight-medium">
                        {`${toReadableNumber(depositEstimate.futureBalanceTe, contractManager.contracts.TE.decimals)} ${contractManager.contracts.TE.name}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
                <hr className="dotted"></hr>
              </div>
              {/* <div className="etx-card__item Card__item tab-s:ph-200 pv-75">

                <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                  <div className="etx-flex__item Flex__item shrink-0">
                    <div className="etx-text Text">
                      <p className="font-label">New Estimated Bot Runtime</p>
                    </div>
                  </div>

                  <div className="etx-flex__item Flex__item grow-1">
                    <div className="etx-text Text a-right">
                      <p className="font-size-regular font-weight-medium">
                        {depositEstimates
                          ? depositEstimates?.addlTeNeededToDeposit > 0
                            ? "-"
                            : depositEstimates?.newGuaranteedBotRuntime > 1000000
                              ? "∞"
                              : depositEstimates?.newGuaranteedBotRuntime + " Days"
                          : "-"
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="etx-card__item Card__item tab-s:ph-200 pv-75">

                <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                  <div className="etx-flex__item Flex__item shrink-0">
                    <div className="etx-text Text">
                      <p className="font-label">
                        Future Balance
                        <i
                          className="far fa-info-circle"
                          title={`New total balance of ${contractManager.cash.name}.`}
                        ></i>
                      </p>
                    </div>
                  </div>

                  <div className="etx-flex__item Flex__item grow-1">
                    <div className="etx-text Text a-right">
                      <p className="font-size-regular font-weight-medium">
                        {toReadableNumber(depositEstimate.futureBalanceCash, contractManager.cash.decimals) + " " + contractManager.cash.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (dialogTab === "WITHDRAW" || dialogTab === "WITHDRAW_NOW") && infoExpanded === true ? (
            <>
              {/* <div className="etx-card__item Card__item tab-s:ph-200 pv-75">
                <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                  <div className="etx-flex__item Flex__item shrink-0">
                    <div className="etx-text Text">
                      <p className="font-label">Total Profits</p>
                    </div>
                  </div>

                  <div className="etx-flex__item Flex__item grow-1">
                    <div className="etx-text Text a-right">
                      <p className="font-size-regular font-weight-medium">
                        {withdrawEstimates ? withdrawEstimates.totalProfits + " USDC" : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
                <hr className="dotted"></hr>
              </div>
              <div className="etx-card__item Card__item tab-s:ph-200 pv-75">
                <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                  <div className="etx-flex__item Flex__item shrink-0">
                    <div className="etx-text Text">
                      <p className="font-label">Profit Share</p>
                    </div>
                  </div>

                  <div className="etx-flex__item Flex__item grow-1">
                    <div className="etx-text Text a-right">
                      <p className="font-size-regular font-weight-medium">
                        {withdrawEstimates ? withdrawEstimates.profitFee + " USDC" : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
                <hr className="dotted"></hr>
              </div> */}
              <div className="etx-card__item Card__item tab-s:ph-200 pv-75">
                <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                  <div className="etx-flex__item Flex__item shrink-0">
                    <div className="etx-text Text">
                      <p className="font-label">
                        <b>Net-Profit</b>
                      </p>
                    </div>
                  </div>

                  <div className="etx-flex__item Flex__item grow-1">
                    <div className="etx-text Text a-right">
                      <p className="font-size-regular font-weight-medium">
                        {toReadableNumber(withdrawEstimate.netProfitShare, contractManager.cash.decimals) + " " + contractManager.cash.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
                <hr className="dotted"></hr>
              </div>
              <div className="etx-card__item Card__item tab-s:ph-200 pv-75">
                <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                  <div className="etx-flex__item Flex__item shrink-0">
                    <div className="etx-text Text">
                      <p className="font-label">
                        Remaining Balance
                        {/* <i
                          className="far fa-info-circle"
                          title="Minimum of 1.0 USDC balance required."
                        ></i> */}
                      </p>
                    </div>
                  </div>

                  <div className="etx-flex__item Flex__item grow-1">
                    <div className="etx-text Text a-right">
                      <p className="font-size-regular font-weight-medium">
                        {toReadableNumber(withdrawEstimate.futureBalanceCash, contractManager.cash.decimals) + " " + contractManager.cash.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
                <hr className="dotted"></hr>
              </div>
              <div className="etx-card__item Card__item tab-s:ph-200 pv-75">
                <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                  <div className="etx-flex__item Flex__item shrink-0">
                    <div className="etx-text Text">
                      <p className="font-label">
                        Remaining Balance
                      </p>
                    </div>
                  </div>

                  <div className="etx-flex__item Flex__item grow-1">
                    <div className="etx-text Text a-right">
                      <p className="font-size-regular font-weight-medium">
                        {`${toReadableNumber(withdrawEstimate.futureBalanceTe, contractManager.contracts.TE.decimals)} ${contractManager.contracts.TE.name}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
                <hr className="dotted"></hr>
              </div>
              <div className="etx-card__item Card__item tab-s:ph-200 pv-75">
                <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                  <div className="etx-flex__item Flex__item shrink-0">
                    <div className="etx-text Text">
                      <p className="font-label">Remaining Estimated Bot Runtime</p>
                    </div>
                  </div>

                  <div className="etx-flex__item Flex__item grow-1">
                    <div className="etx-text Text a-right">
                      <p className="font-size-regular font-weight-medium">
                        {withdrawEstimates ?
                          withdrawEstimates.newTeBalance > 0 || withdrawEstimates.tcToReceive > 0 ?
                            props.user?.upkeep.fee === 0 ||
                              (withdrawEstimates.newTeBalance > 0
                                && withdrawEstimates.newCashBalance === 0
                                && withdrawEstimates.cashToReceive > 0) ?
                              "∞" :
                              withdrawEstimates.newGuaranteedBotRuntime + " Days" : "0 Days" : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </>
          ) : <></>}
          {
            infoExpanded === true ?
              <div className="etx-card__item Card__item ph-0 pt-50 pb-0">
                <hr className="dotted"></hr>
              </div> : <></>
          }

          <div className="etx-card__item Card__item tab-s:ph-200 pv-50">

            <div className="etx-button-list ButtonList a-center">

              <button className="etx-button Button transparent" onClick={() => setInfoExpanded(!infoExpanded)}>
                <span className="etx-button__text Button__text">{infoExpanded ? "Hide Details" : "Show Details"}</span>
                <div className="etx-button__icon Button__icon">
                  <i className={"fal " + (infoExpanded ? "fa-angle-up" : "fa-angle-down")}></i>
                </div>
              </button>

            </div>

          </div>

          <div className="etx-card__item Card__item ph-0 pv-0">
            <div className="etx-button-list ButtonList">
              {getActionButton()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
