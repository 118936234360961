import React from "react";
import { BigNumber, flattenObj, GREEN_TEXT, logicallyLimitDecimals, RED_TEXT, WHITE_TEXT } from "src/global/utils";
import { ContractsManagerContext, FunctionsContext, StrategyContext, WalletContext } from "src/global/contexts";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { ethers } from "ethers";
import { OUTCOME_WATCHER_WALLET, STRATEGY_TRADERS, TRADING_ENGINE_BASE_URL } from "src/global/constants";
import { TradeStatus } from "src/pages/strategy/misc/TradeStatus";
import { defaultSliderValue, IBalances, IPrices } from "src/pages/trade/Sidebar";

const tabColour = {
  "long": "var(--color-long-selected)",
  "short": "var(--color-short-selected)",
};

const tabColourHover = {
  "long": "var(--color-dark-2)",
  "short": "var(--color-dark-4)",
};

const bgColor = {
  "long": "var(--color-dark-1)",
  "short": "var(--color-dark-3)",
};

const sliderColor = {
  "long": "var(--color-long-2)",
  "short": "var(--color-short-2)",
};

// const blue = {
//   50: "#F0F7FF",
//   100: "#C2E0FF",
//   200: "#80BFFF",
//   300: "#66B2FF",
//   400: "#3399FF",
//   500: "#007FFF",
//   600: "#0072E5",
//   700: "#0059B2",
//   800: "#004C99",
//   900: "#003A75",
// };

interface IProps {
  tradeTypeSelected: "long" | "short";
  setTradeTypeSelected: (a: "long" | "short") => void;
  sliderValue: string;
  setSliderValue: (a: string) => void;
  prices: IPrices;
  balances: IBalances;
}

export function Input(props: IProps) {
  const { wallet } = React.useContext(WalletContext);
  const { contractManager } = React.useContext(ContractsManagerContext);
  const { selectedStrategy } = React.useContext(StrategyContext);
  const { showDialog, showErrorMessage, setLoader } = React.useContext(FunctionsContext);

  const [orderTypeTabValue, setOrderTypeTabValue] = React.useState(1);
  const [acceptablePriceEnter, setAcceptablePriceEnter] = React.useState(0);
  const [acceptablePriceExit, setAcceptablePriceExit] = React.useState(0);

  const [spinArrowEnter, setSpinArrowEnter] = React.useState(false);
  const [spinArrowExit, setSpinArrowExit] = React.useState(false);

  // TODO: implement checkbox and instant-limit orders
  // // Wait for prices to be updated, then pull the prices from it
  // React.useEffect(() => {
  //   if (!acceptablePriceEnter) {
  //     resetPriceEnter();
  //   }

  //   if (!acceptablePriceExit) {
  //     resetPriceExit();
  //   }
  // }, [props.prices]);

  // Wait for prices to be updated, then pull the prices from it
  React.useEffect(() => {
    resetPriceEnter();
    resetPriceExit();
  }, [props.prices]);

  // Update prices upon switching tradeTypeSelected
  React.useEffect(() => {
    resetPriceEnter();
    resetPriceExit();
  }, [props.tradeTypeSelected]);

  function getPriceFromOrder(order: number): number {
    switch (order) {
      case 1: return props.prices.zeroX.longEntry;
      case 2: return props.prices.zeroX.longExit;
      case -1: return props.prices.zeroX.shortEntry;
      case -2: return props.prices.zeroX.shortExit;
    }
    return props.prices.coinbase;
  }

  function calcSlippage(order: number) {
    const price = getPriceFromOrder(order);
    switch (order) {
      case 1: return (props.prices.coinbase - price) * 100 / props.prices.coinbase;
      case 2: return (price - props.prices.coinbase) * 100 / props.prices.coinbase;
      case -1: return (price - props.prices.coinbase) * 100 / props.prices.coinbase;
      case -2: return (props.prices.coinbase - price) * 100 / props.prices.coinbase;
    }
    return 0;
  }

  function getSlippage(order: number) {
    let slippage = calcSlippage(order);
    if (slippage < 0 && slippage > -0.0055) {
      slippage = 0;
    }

    return (
      slippage >= 0
        ? "+" + Math.abs(slippage).toFixed(2) + "%"
        : slippage.toFixed(2) + "%"
    );
  }

  const stylingFromSlippage = (order: number) => {
    const slippage = calcSlippage(order);
    const turnGreenAt = 0.01;
    const turnRedAt = -1.00;

    if (!slippage || slippage > 100 || slippage < -100) {
      return { color: "var(--color-white-1-op-50)" };
    }

    return (
      slippage < turnGreenAt && slippage > turnRedAt
        ? {}
        : {
          color:
            slippage >= turnGreenAt
              ? GREEN_TEXT
              : slippage <= turnRedAt
                ? RED_TEXT
                : WHITE_TEXT
        }
    );
  };

  const getOrderFromOutcome = (orderType: "enter" | "exit") => {
    const order =
      props.tradeTypeSelected === "short"
        ? orderType === "exit"
          ? -2
          : -1
        : orderType === "exit"
          ? 2
          : 1;
    return order;
  };

  const buildSlippageOutcome = (orderType: "enter" | "exit") => {
    const order = getOrderFromOutcome(orderType);
    const styleObj = stylingFromSlippage(order);
    return (
      <td className="font-size-medium font-weight-medium" style={styleObj}>
        {getSlippage(order)}
      </td>
    );
  };

  const buildPriceOutcome = (orderType: "enter" | "exit") => {
    const order = getOrderFromOutcome(orderType);
    const styleObj = stylingFromSlippage(order);
    return (
      <td className="font-size-medium font-weight-medium" style={styleObj}>
        {logicallyLimitDecimals(getPriceFromOrder(order))}
      </td>
    );
  };

  function throwTradeErrorOnInvalidMessage(deadline: number) {
    const diff = deadline - Math.floor(Date.now() / 1000);
    showDialog(
      <h3 style={{ color: "rgba(204, 109, 57, 0.8)" }}>
        TRADE ERROR: DEADLINE
      </h3>,
      <>
        <p style={{ height: "15px" }}></p>
        <h5>
          NO TRADE TOOK PLACE
        </h5>
        <p style={{ height: "15px" }}></p>
        <div>
          Try signing the message again.
          <br />
          <br />
          Ensure you do not linger on the message signing prompt.
          <br />
          <br />
          If that does not solve the problem, contact Trendespresso (<a href="mailto:contact@trendespresso.com">contact@trendespresso.com</a>)
          with the following error code:
          <p style={{ height: "10px" }}></p>
          <div style={{ backgroundColor: "var(--chartColour)", height: "70px", textAlign: "center", paddingTop: "22px" }}>
            <code>
              $$__deadline_{deadline}_{Math.floor(Date.now() / 1000)}_{diff >= 0 ? "+" : ""}{diff}__$$
            </code>
          </div>
        </div>
      </>
    );
  }

  async function signTradingSignature(order: number) {
    // TODO Learn how to do this ...
    // setCurrentTradePositions({ long: BigNumber(10), short: BigNumber(100) });
    if (!contractManager.contracts.TradingSignature.functions) {
      throw new Error("No TradingSignature functions in signTradingSignature()!");
    }

    if (!contractManager.contracts.Controller.functions) {
      throw new Error("No Controller functions in signTradingSignature()!");
    }

    if (!contractManager.contracts.DepositWithdraw.functions) {
      throw new Error("No DepositWithdraw functions in signTradingSignature()!");
    }

    const chainId = (await wallet.provider?.getNetwork())?.chainId;
    if (!chainId) {
      throw new Error("No chainId in signTradingSignature()!");
    }

    if (props.tradeTypeSelected === "short") {
      order *= -1;
    }

    // If the requested trade is an EXIT, and there's no active trade, then show a popup.
    if (order > 1 || order < -1) {
      const tradeStatus = await contractManager.contracts.DepositWithdraw.functions.getTradeStatusOf(
        await contractManager.contracts.DepositWithdraw.functions.accountOwnedBy(
          OUTCOME_WATCHER_WALLET
        )
      );

      if (order > 1 && tradeStatus[2].toUpperCase() !== "F") {
        showDialog("No Active Long Position", "Cannot exit a non-existant Long position. Enter a Long position before attempting to exit one.");
        return;
      }
      else if (order < -1 && (tradeStatus[3] === "0" || tradeStatus[3] === "1")) {
        showDialog("No Active Short Position", "Cannot exit a non-existant Short position. Enter a Short position before attempting to exit one.");
        return;
      }
    }

    const domain = {
      name: await contractManager.contracts.TradingSignature.functions.name(),
      version: "1",
      chainId,
      verifyingContract: contractManager.contracts.TradingSignature.address
    };

    const domainType = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" }
    ];

    const getBlockTimestampForMsg: ethers.BigNumber = await contractManager.contracts.TradingSignature.functions.getBlockTimestampForMsg();
    // Sun Dec 26 4821 16:00:00 GMT+0000
    if (getBlockTimestampForMsg.gt(90000000000)) {
      throw new Error("TradingSignature.getBlockTimestampForMsg() INVALID: " + getBlockTimestampForMsg.toString());
    }

    const verifyTradeSignature = {
      owner: wallet.address,
      strategyNum: selectedStrategy.num,
      pctToTrade: Number(props.sliderValue),
      order,
      orderType: `0x0${orderTypeTabValue}`, // where 0x01 is a Market order, 0x02 is a Limit order, etc
      price: order > 1 || order < -1 ? acceptablePriceExit : acceptablePriceEnter,
      // Average genuine time outside the blockchain with the blockchain's ``block.timestamp``
      deadline: (
        chainId === 31337
          ? Number(getBlockTimestampForMsg)
          : Math.floor(Math.max(
            Number(getBlockTimestampForMsg),
            Math.floor(Date.now() / 1000 + 10)
          ))
      )
      // deadline: Math.floor(Date.now() / 1000) + Number(msgValidForSeconds)
    };

    console.log("Assembling trade ...", Number(getBlockTimestampForMsg) < Math.floor(Date.now() / 1000 + 10) ? "-> Using fallback timestamp" : "## Computed timestamp");

    const VerifyTradeSignature = [
      { type: "address", name: "owner" },
      { type: "uint256", name: "strategyNum" },
      { type: "uint256", name: "pctToTrade" },
      { type: "int256", name: "order" },
      { type: "bytes1", name: "orderType" },
      { type: "uint256", name: "price" },
      { type: "uint256", name: "deadline" }
    ];

    const data = {
      types: {
        EIP712Domain: domainType,
        VerifyTradeSignature
      },
      domain: domain,
      primaryType: "VerifyTradeSignature",
      message: verifyTradeSignature
    };

    console.log("verifyTradeSignature", verifyTradeSignature);

    const dataToSign = JSON.stringify(data);

    // call this method to sign EIP 712 data
    try {
      const signTyped = async (dataToSign: any) => wallet.provider?.send("eth_signTypedData_v4", [wallet.address, dataToSign]);

      const splitSig = (sig: string) => {
        // splits the signature to r, s, and v values.
        const pureSig = sig.replace("0x", "");

        const r = "0x" + pureSig.substring(0, 64);
        const s = "0x" + pureSig.substring(64, 128);
        const v = parseInt(pureSig.substring(128, 130), 16);

        return { v, r, s };
      };

      const signature = await signTyped(dataToSign);
      const split = splitSig(signature);

      console.log("ARGS:", ...flattenObj(verifyTradeSignature), ...flattenObj(split));
      console.log(await contractManager.contracts.TradingSignature.functions.verifyTradeSignature(...flattenObj(verifyTradeSignature), ...flattenObj(split)));

      // showErrorMessage(error);
      if (!(await contractManager.contracts.TradingSignature.functions.verifyTradeSignature(...flattenObj(verifyTradeSignature), ...flattenObj(split)))) {
        throwTradeErrorOnInvalidMessage(verifyTradeSignature.deadline);
        if (chainId === 31337) {
          console.warn("SIGNATURE VALIDATION FAILED. DID YOU RUN saveDomainSeparatorAndChainId() on TradingSignature.sol ???");
        }
        return;
      }

      const failedSignerValidation = async () => {
        const errCtrlTradingAuth = 2; // from the smart contracts ``constants.sol``
        if (!contractManager.contracts.Controller.functions) {
          console.warn("failedSignerValidation(): contractManager.contracts.Controller.functions is undefined");
          return;
        }
        const errorMessage = await contractManager.contracts.Controller.functions.errors(errCtrlTradingAuth) + ".";
        showErrorMessage(errorMessage);
      };

      // Authenticate the trader
      // eslint-disable-next-line no-lone-blocks
      {
        let goodSigner = false;
        if (verifyTradeSignature.strategyNum <= STRATEGY_TRADERS.length - 1) {
          goodSigner = STRATEGY_TRADERS[verifyTradeSignature.strategyNum].traders.includes(verifyTradeSignature.owner);
        }
        if (
          !goodSigner
          && (await contractManager.contracts.Controller.functions.getTradingWalletOf(verifyTradeSignature.strategyNum)) !== verifyTradeSignature.owner
          && !(await contractManager.contracts.Controller.functions.isAdminOrBackend(verifyTradeSignature.owner))
        ) {
          failedSignerValidation();
          return;
        }
      }

      const orderType = "Market"; // TODO Support "Limit" and "Conditional Limit" in the future
      // const pair = "BTC-USD"; // TODO Enable support for ETH and AVAX pairs as well
      // const coinbaseData = await fetch(`https://api.coinbase.com/v2/prices/${pair}/spot`);
      // const price = (await coinbaseData.json()).data.amount;

      // TODO: fetch the price somewhere, display it on the page, and send it off with the webhook
      try {
        setLoader(true);
        // setTimeout(() => setLoader(true), 500);

        const tradeData = {
          tradeSignature: {
            ...verifyTradeSignature,
            ...split
          }
        };

        const res = await fetch(`${TRADING_ENGINE_BASE_URL}/trade`, {
          method: "POST",
          body: JSON.stringify(tradeData),
          headers: { "Content-type": "application/json; charset=UTF-8" }
        });

        console.log(`STATUS ${res.status} returned after submitting the following trade:`);
        console.log(tradeData);

        // invalid `order` number
        if (res.status === 481) {
          setLoader(false);
          showDialog(
            <h3 style={{ color: "rgba(204, 109, 57, 0.8)" }}>
              TRADE ERROR: ORDER
            </h3>,
            <>
              <p style={{ height: "15px" }}></p>
              <h5>
                NO TRADE TOOK PLACE
              </h5>
              <p style={{ height: "15px" }}></p>
              <div>
                Invalid order number.
                <br />
                <br />
                Please try refreshing the page and submitting the trade again.
                If that does not solve the problem,
                contact Trendespresso (<a href="mailto:contact@trendespresso.com">contact@trendespresso.com</a>)
                with the following error code:
                <p style={{ height: "10px" }}></p>
                <div style={{ backgroundColor: "var(--chartColour)", height: "70px", textAlign: "center", paddingTop: "22px" }}>
                  <code>
                    $$__order_{await res.text()}__$$
                  </code>
                </div>
              </div>
            </>
          );
          return;
        }

        // invalid `slippage`
        if (res.status === 482) {
          setLoader(false);
          showDialog(
            <h3 style={{ color: "rgba(204, 109, 57, 0.8)" }}>
              TRADE ERROR: SLIPPAGE
            </h3>,
            <>
              <p style={{ height: "15px" }}></p>
              <h5>
                NO TRADE TOOK PLACE
              </h5>
              <p style={{ height: "15px" }}></p>
              <div>
                Slippage too tight. Try adjusting your required price.
                <br />
                <br />
                If you loosen your required price and that does not solve the problem,
                contact Trendespresso (<a href="mailto:contact@trendespresso.com">contact@trendespresso.com</a>)
                with the following error code:
                <p style={{ height: "10px" }}></p>
                <div style={{ backgroundColor: "var(--chartColour)", height: "70px", textAlign: "center", paddingTop: "22px" }}>
                  <code>
                    $$__slippage_{await res.text()}__$$
                  </code>
                </div>
              </div>
            </>
          );
          return;
        }

        // invalid `signature`
        if (res.status === 401) {
          failedSignerValidation();
          return;
        }

        // general bad request
        if (res.status === 400) {
          throwTradeErrorOnInvalidMessage(verifyTradeSignature.deadline);
        }

        setTimeout(() => {
          setLoader(false);
          setTimeout(() => {
            showDialog("SUCCESS", "Trade successfully submitted. Signature was successfully verified. Trading Engine is processing!");
          }, 500);
        }, 2000);
      }
      catch (error) {
        setLoader(false);
        showDialog(
          "NETWORK ERROR",
          `The URL "${TRADING_ENGINE_BASE_URL}/trade" is inaccessible. Please alert contact@trendespresso.com`
        );
        console.log(error);
      }
    }
    catch (error) {
      showErrorMessage(error as any);
    }
  }

  function unstyledTabsCustomized() {
    return (
      // Documentation: https://mui.com/base-ui/react-tabs/components-api/#tabs
      <Tabs defaultValue={1} onChange={(e: any, newValue) => setOrderTypeTabValue(Number(newValue))}>
        <TabsList>
          <Tab value={1}>Market</Tab>
          <Tab value={2} disabled={true}>Limit</Tab>
          <Tab value={3} disabled={true}>Conditional Limit</Tab>
        </TabsList>
        {/* <TabPanel value={1}>First page</TabPanel>
        <TabPanel value={2}>Second page</TabPanel>
        <TabPanel value={3}>Third page</TabPanel> */}
      </Tabs>
    );
  }

  const TabsList = styled(BaseTabsList)(
    ({ theme }) => `
    width: calc(var(--sidebarWidth) - var(--etx-flex-gutter-h));
    background-color: ${bgColor[props.tradeTypeSelected]};
    border-radius: 6px;
    margin-bottom: 16px;
    margin-top: 16px;
    // margin-left: calc(var(--etx-flex-gutter-h) / 2);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 4px 6px ${theme.palette.mode === "dark" ? "rgba(0,0,0, 0.4)" : "rgba(0,0,0, 0.2)"};
    `
  );

  const Tab = styled(BaseTab)`
    font-family: "IBM Plex Sans", sans-serif;
    color: white;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    line-height: 1.5;
    padding: 8px 12px;
    margin: 6px;
    border: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;

    &:hover {
      background-color: ${tabColourHover[props.tradeTypeSelected]};
    }

    &:focus {
      color: #fff;
    }

    &.${tabClasses.selected} {
      outline: 1px dotted black;
      background-color: ${tabColour[props.tradeTypeSelected]};
      color: white;
    }

    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

  function resetPriceEnter() {
    // Prevent user pressing the button while pricesObj is loading
    if (
      (props.tradeTypeSelected === "long" && !props.prices.zeroX.longEntry)
      || (props.tradeTypeSelected === "short" && !props.prices.zeroX.shortEntry)
    ) {
      return;
    }

    setSpinArrowEnter(true);
    setAcceptablePriceEnter(logicallyLimitDecimals(getPriceFromOrder(getOrderFromOutcome("enter"))));
    setTimeout(() => setSpinArrowEnter(false), 350); // Make sure this is in-sync with `cycleArrows` duration in style.css
  }

  function resetPriceExit() {
    // Prevent user pressing the button while pricesObj is loading
    if (
      (props.tradeTypeSelected === "long" && !props.prices.zeroX.longExit)
      || (props.tradeTypeSelected === "short" && !props.prices.zeroX.shortExit)
    ) {
      return;
    }

    setSpinArrowExit(true);
    setAcceptablePriceExit(logicallyLimitDecimals(getPriceFromOrder(getOrderFromOutcome("exit"))));
    setTimeout(() => setSpinArrowExit(false), 350); // Make sure this is in-sync with `cycleArrows` duration in style.css
  }

  return (
    <div className="etx-card Card bg-white-1-20 border-radius overflow-hidden">
      <div className="etx-card__item Card__item ph-0 pv-0">
        <div
          className="etx-button-list ButtonList combo card-header"
        >
          <button
            className={
              "etx-button Button border-radius-0 tradeTabsLong"
              + (props.tradeTypeSelected === "long" ? "Active" : "")
            }
            onClick={() => props.setTradeTypeSelected("long")}
          >
            <span className="etx-button__text Button__text">
              LONG
            </span>
          </button>

          <button
            className={
              "etx-button Button border-radius-0 tradeTabsShort"
              + (props.tradeTypeSelected === "short" ? "Active" : "")
            }
            onClick={() => props.setTradeTypeSelected("short")}
          >
            <span className="etx-button__text Button__text">
              SHORT
            </span>
          </button>
        </div>
      </div>

      <div style={{ margin: "auto" }}>
        {unstyledTabsCustomized()}
      </div>

      <div className="etx-card__item Card__item ph-0 pv-0">
        <hr />
      </div>

      <div className="etx-card__item Card__item pv-50">
        <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
          <div className="etx-flex__item Flex__item shrink-0">
            <div className="etx-text Text">
              <p className="font-label">{contractManager.cash.name} Available</p>
            </div>
          </div>

          <div className="etx-flex__item Flex__item grow-1">
            <div className="etx-text Text a-right">
              <p className="font-size-large font-weight-medium">
                {Number(BigNumber(props.balances.cashAvailable).mul(1000).div(contractManager.cash.denominator)) / 1000}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="etx-card__item Card__item ph-0 pv-0">
        <hr />
      </div>

      <TradeStatus
        fieldName="Current Trade"
        balances={props.balances}
        setTradeTypeSelected={props.setTradeTypeSelected}
      />

      <div className="etx-card__item Card__item ph-0 pv-0">
        <hr />
      </div>

      <div className="etx-card__item Card__item pv-50">
        <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
          <div className="etx-flex__item Flex__item shrink-0">
            <div className="etx-text Text">
              <p className="font-label">Percent Position</p>
            </div>
          </div>

          <div className="etx-flex__item Flex__item grow-1">
            <div className="etx-text Text a-right">
              <p className="font-size-large font-weight-medium">
                {props.sliderValue}
              </p>
            </div>
          </div>

          <div className="etx-flex__item Flex__item shrink-0">
            <Box sx={{ minWidth: "var(--tradeSliderWidth)", flex: "0 0 auto", marginTop: "4.5px" }}>
              <Slider
                aria-label="Percent Position"
                defaultValue={Number(props.sliderValue)}
                getAriaValueText={(a, b) => "getAriaValueText"}
                valueLabelDisplay="auto"
                shiftStep={25}
                step={5}
                marks
                min={0}
                max={100}
                onChange={e => props.setSliderValue((e.target as HTMLInputElement).value ?? defaultSliderValue)}
                style={{
                  color: sliderColor[props.tradeTypeSelected],
                  height: "5px", // default is 4px
                  borderRadius: "1px"
                }}
              />
              {/* EXAMPLE OF A DISABLED SLIDER: */}
              {/* <Slider defaultValue={30} step={10} marks min={10} max={110} disabled /> */}
            </Box>
          </div>
        </div>
      </div>

      <div className="etx-card__item Card__item ph-0 pv-0">
        <hr />
      </div>

      <div className="etx-card__item traderStatusOutcome">
        <span className="font-label">Estimated Outcome</span>
      </div>

      <div style={{ width: "100%", textAlign: "center", justifyContent: "center" }}>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              {buildSlippageOutcome("enter")}
              {buildSlippageOutcome("exit")}
            </tr>
            <tr>
              {buildPriceOutcome("enter")}
              {buildPriceOutcome("exit")}
            </tr>
            <tr><td><div style={{ height: "var(--tradeStatusSidebarTableRowSpacing)" }}></div></td></tr>
            {/* <tr>
              <td>
                <div className="FormField font-size-small">
                  <div className="FormField__input" style={{ justifyContent: "center", padding: "0" }}>
                    <input
                      // type="number"
                      className="leftSideBorderRadius"
                      placeholder="0"
                      value={acceptablePriceEnter}
                      onChange={e => setAcceptablePriceEnter(Number(e.target.value))}
                      onFocus={e => e.target.select()}
                      style={{ width: "50%", flex: "0 0 auto" }}
                    />
                    <button
                      className="FormField__input__button rightSideBorderRadius"
                      onClick={resetPriceEnter}
                      style={{ fontSize: "10px" }}
                    >
                      <i className={`fal fa-rotate-right cycleArrows350 ${spinArrowEnter ? "fa-spin" : ""}`}></i>
                    </button>
                  </div>

                  <div className="FormField__label"></div>
                </div>
              </td>
              <td>
                <div className="FormField font-size-small">
                  <div className="FormField__input" style={{ justifyContent: "center" }}>
                    <input
                      // type="number"
                      className="leftSideBorderRadius"
                      placeholder="0"
                      value={acceptablePriceExit}
                      onChange={e => setAcceptablePriceExit(Number(e.target.value))}
                      onFocus={e => e.target.select()}
                      style={{ width: "50%", flex: "0 0 auto" }}
                    />

                    <button
                      className="FormField__input__button rightSideBorderRadius"
                      onClick={resetPriceExit}
                    >
                      <i className={`fal fa-rotate-right cycleArrows350 ${spinArrowExit ? "fa-spin" : ""}`}></i>
                    </button>
                  </div>

                  <div className="FormField__label"></div>
                </div>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>

      <div className="etx-card__item pv-50"></div>

      <div className="etx-card__item Card__item ph-0 pv-0">
        <div className="etx-button-list ButtonList combo">
          <button
            className="etx-button Button border-radius-0 enterTradeButton"
            onClick={() => signTradingSignature(1)}
          >
            <span className="etx-button__text Button__text">ENTER</span>
          </button>
          <button
            className="etx-button Button border-radius-0 exitTradeButton"
            onClick={() => signTradingSignature(2)}
          >
            <span className="etx-button__text Button__text">EXIT</span>
          </button>
        </div>
      </div>

      {/* <div className="etx-card__item Card__item ph-0 pv-0">
        <div className="etx-button-list ButtonList">
          <button
            className="etx-button Button fullwidth border-radius-0 has-info cancelPendingTransfersColour"
            title="Cancels any pending transfers from the Bot."
            onClick={() => cancelPendingWithdrawals()}
          >
            <div className="etx-button__icon Button__icon">
              <i className="far fa-times"></i>
            </div>
            <span className="etx-button__text Button__text" style={{ fontSize: "12px" }}>Cancel Pending Withdrawals</span>
          </button>
        </div>
      </div> */}
    </div>
  );
}
