import React from "react";
import { useDebouncedCallback } from "use-debounce";
import useInterval from "use-interval";
import { TE_CONSTANTS, TRADING_ENGINE_BASE_URL } from "src/global/constants";
import { ContractsManagerContext, FunctionsContext, NetworkContext, SwapFunctionsContext, WalletContext } from "src/global/contexts";
import { ITokenContract } from "src/ABIs/contract_map";
import { BigNumber, getPriceFromCoinbase, logicallyLimitDecimals, toReadableNumber, toSolidityNumber } from "src/global/utils";
import { ethers } from "ethers";

type SwapActions = "APPROVE" | "SWAP";

interface ISources {
  name: string;
  proportion: string;
}

export interface IPriceData {
  chainId: number;
  price: string;
  grossPrice: string;
  estimatedPriceImpact: string;
  value: string;
  gasPrice: string;
  gas: string;
  estimatedGas: string;
  protocolFee: string;
  minimumProtocolFee: string;
  buyTokenAddress: string;
  buyAmount: string;
  grossBuyAmount: string;
  sellTokenAddress: string;
  sellAmount: string;
  grossSellAmount: string;
  sources: ISources[];
  auxiliaryChainData: any;
}

export const defaultPriceData: IPriceData = {
  chainId: 31337,
  price: "0",
  grossPrice: "0",
  estimatedPriceImpact: "0",
  value: "0",
  gasPrice: "0",
  gas: "0",
  estimatedGas: "0",
  protocolFee: "0",
  minimumProtocolFee: "0",
  buyTokenAddress: ethers.constants.AddressZero,
  buyAmount: "0",
  grossBuyAmount: "0",
  sellTokenAddress: ethers.constants.AddressZero,
  sellAmount: "0",
  grossSellAmount: "0",
  sources: [{ name: "N/A", proportion: "0" }],
  auxiliaryChainData: {}
};

export interface ISwapData {
  chainId: number;
  price: string;
  grossPrice: string;
  estimatedPriceImpact: string;
  value: string;
  gasPrice: string;
  gas: string;
  estimatedGas: string;
  protocolFee: string;
  minimumProtocolFee: string;
  buyTokenAddress: string;
  buyAmount: string;
  grossBuyAmount: string;
  sellTokenAddress: string;
  sellAmount: string;
  grossSellAmount: string;
  sources: ISources[];
  sellTokenToEthRate: string;
  buyTokenToEthRate: string;
  to: string;
  data: string;
  decodedUniqueId: string;
  guaranteedPrice: string;
  orders: any[]; // Not sure what this array of objects is
  auxiliaryChainData: any;
}

const defaultSwapData: ISwapData = {
  chainId: 31337,
  price: "0",
  grossPrice: "0",
  estimatedPriceImpact: "0",
  value: "0",
  gasPrice: "0",
  gas: "0",
  estimatedGas: "0",
  protocolFee: "0",
  minimumProtocolFee: "0",
  buyTokenAddress: ethers.constants.AddressZero,
  buyAmount: "0",
  grossBuyAmount: "0",
  sellTokenAddress: ethers.constants.AddressZero,
  sellAmount: "0",
  grossSellAmount: "0",
  sources: [{ name: "N/A", proportion: "0" }],
  sellTokenToEthRate: "0",
  buyTokenToEthRate: "0",
  to: ethers.constants.AddressZero,
  data: ethers.constants.AddressZero,
  decodedUniqueId: ethers.constants.AddressZero,
  guaranteedPrice: "0",
  orders: [],
  auxiliaryChainData: {}
};

export interface ISwapDataDeadline extends ISwapData {
  deadline: string;
}

const defaultSwapDataDeadline: ISwapDataDeadline = {
  ...defaultSwapData,
  deadline: "0"
};

const tokens = [
  {
    name: "TE",
    value: "TE",
    contractKey: "TE",
  },
  {
    name: "USDC",
    value: "USDC",
    contractKey: "USDC",
  },
  {
    name: "AVAX",
    value: "AVAX",
    contractKey: "Coin", // TODO Make this compatible for Arbitrum
  },
  {
    name: "BTC.b",
    value: "BTCb",
    contractKey: "BTC",
  },
  {
    name: "WBTC.e",
    value: "WBTCe",
    contractKey: "BTC_alt",
  },
  {
    name: "WETH.e",
    value: "WETHe",
    contractKey: "ETH",
  },
];

export function Control() {
  const { contractManager } = React.useContext(ContractsManagerContext);
  const { network } = React.useContext(NetworkContext);
  const { approve, subscribeToBlockchain, unsubscribeToBlockchain } = React.useContext(FunctionsContext);
  const { getTokenApprovedAmount, getTokenBalance, swapTokenHolder, swap } = React.useContext(SwapFunctionsContext);
  const { wallet } = React.useContext(WalletContext);
  const { setLoader, showDialog, createPermit, showErrorMessage } = React.useContext(FunctionsContext);

  const [tokenFromBalance, setTokenFromBalance] = React.useState(BigNumber(0));
  const [tokenFrom, setTokenFrom] = React.useState(contractManager.contracts.USDC);

  const [tokenToBalance, setTokenToBalance] = React.useState(BigNumber(0));
  const [tokenTo, setTokenTo] = React.useState(contractManager.contracts.TE);

  const [fromApprovalAmount, setFromApprovalAmount] = React.useState(BigNumber(0));
  const [amountFrom, setAmountFrom] = React.useState(BigNumber(0));
  const [amountFrom_str, setAmountFrom_str] = React.useState("");
  const [amountTo, setAmountTo] = React.useState(BigNumber(0));

  const [currentSlippage, setCurrentSlippage] = React.useState(0.1);
  const [swapData, setSwapData] = React.useState(defaultSwapDataDeadline);
  const [exchangeRate, setExchangeRate] = React.useState(0);

  const defaultExchangeRateDigits = 4;
  const [exchangeRateDigits, setExchangeRateDigits] = React.useState(defaultExchangeRateDigits);

  const [isLoadingBalance, setIsLoadingBalance] = React.useState(false);
  const [isLoadingApproval, setIsLoadingApproval] = React.useState(false);
  const [isLoadingSwapData, setIsLoadingSwapData] = React.useState(false);
  const [infoExpanded, setInfoExpanded] = React.useState(false);

  function onFromAmountSet(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.target.value.replace(/[^0-9.]/g, "");

    if (val.includes(".") && val.split(".")[1].length > tokenFrom.decimals.toNumber()) {
      return;
    }

    const newFromAmount = toSolidityNumber(val, tokenFrom.decimals);
    setAmountFrom(newFromAmount);
    setAmountFrom_str(val);

    setIsLoadingApproval(true);
    getFromApprovalAmount(tokenFrom);

    setIsLoadingSwapData(true);
    getSwapData();
  };

  function setMaxCash() {
    setAmountFrom(tokenFromBalance);
    setAmountFrom_str(toReadableNumber(tokenFromBalance, tokenFrom.decimals));

    setIsLoadingApproval(true);
    getFromApprovalAmount(tokenFrom);

    setIsLoadingSwapData(true);
    getSwapData();
  };

  function onFromCurrencySet(e: React.ChangeEvent<HTMLSelectElement>) {
    const contracts = contractManager.contracts as unknown as { [key: string]: ITokenContract; };
    // const newTokenFrom = contracts[e.target.value];

    const contractKey = tokens.find((token) => token.value === e.target.value)?.contractKey;
    if (!contractKey) {
      throw new Error("`contractKey` not found in `tokens[]` in `onFromCurrencySet()` in Swap Control");
    }
    console.log("contractKey", contractKey);
    const newTokenFrom = contracts[contractKey];

    setTokenFromBalance(BigNumber(0));
    setTokenFrom(newTokenFrom);
    setAmountFrom(BigNumber(0));
    setAmountFrom_str("0");
    setAmountTo(BigNumber(0));

    setIsLoadingApproval(true);
    getFromApprovalAmount(newTokenFrom);

    setIsLoadingBalance(true);
    getTokenBalanceTimed(newTokenFrom, setTokenFromBalance);
  };

  async function onToCurrencySet(e: React.ChangeEvent<HTMLSelectElement>) {
    const contracts = contractManager.contracts as unknown as { [key: string]: ITokenContract; };
    const contractKey = tokens.find((token) => token.value === e.target.value)?.contractKey;
    if (!contractKey) {
      throw new Error("`contractKey` not found in `tokens[]` in `onToCurrencySet()` in Swap Control");
    }
    console.log("contractKey", contractKey);
    const newTokenTo = contracts[contractKey];

    setTokenToBalance(BigNumber(0));
    setTokenTo(newTokenTo);
    setAmountTo(BigNumber(0));

    setIsLoadingBalance(true);
    getTokenBalanceTimed(newTokenTo, setTokenToBalance);

    setIsLoadingSwapData(true);
    getSwapData();
  };

  const getFromApprovalAmount = useDebouncedCallback(async (token = tokenFrom) => {
    if (token.address.toUpperCase() === contractManager.contracts.Coin.address.toUpperCase()) {
      setFromApprovalAmount(ethers.constants.MaxUint256);
      setIsLoadingApproval(false);
      return;
    }

    let tokenApprovedAmount = BigNumber(0);

    try {
      tokenApprovedAmount = await getTokenApprovedAmount(
        token,
        tokenTo.name === "TE"
          ? contractManager.contracts.TokenHolder.address
          : network.ZeroXExchangeProxy
      );
    }
    catch (error) {
      console.error(error);
    }

    setFromApprovalAmount(tokenApprovedAmount);
    setIsLoadingApproval(false);
  }, 500);

  const getTokenBalanceTimed = useDebouncedCallback(_getTokenBalance, 500);

  async function _getTokenBalance(token: ITokenContract, setter: Function) {
    let tokenBalance = BigNumber(0);

    try {
      tokenBalance = await getTokenBalance(token);
    }
    catch (error) {
      console.error(error);
    }

    setter(tokenBalance);
    setIsLoadingBalance(false);
  };

  const onLoad = useDebouncedCallback((_tokenFrom = tokenFrom, _tokenTo = tokenTo) => {
    if (!_tokenFrom.functions) {
      console.log("No _tokenFrom functions onLoad");
      console.log(_tokenFrom);
      return;
    }

    if (!_tokenTo.functions) {
      console.log("No _tokenTo functions onLoad");
      console.log(_tokenTo);
      return;
    }

    setTokenFrom(_tokenFrom);
    setTokenTo(_tokenTo);

    setIsLoadingApproval(true);
    getFromApprovalAmount(_tokenFrom);

    setIsLoadingBalance(true);
    _getTokenBalance(_tokenFrom, setTokenFromBalance);

    setIsLoadingBalance(true);
    _getTokenBalance(_tokenTo, setTokenToBalance);
  }, 500);

  // Drops the parameter passed in by the subscribeToBlockchain function
  const onLoad_noParams = React.useCallback(() => onLoad(), [onLoad]);

  React.useEffect(() => {
    subscribeToBlockchain(onLoad_noParams);
    return () => unsubscribeToBlockchain(onLoad_noParams);
  }, [onLoad_noParams, tokenFrom, tokenTo, subscribeToBlockchain, unsubscribeToBlockchain]);

  React.useEffect(() => {
    onLoad(contractManager.contracts.USDC, contractManager.contracts.TE);
  }, [onLoad, contractManager.contracts]);

  useInterval(() => {
    if (amountFrom.gt(0) && !isLoadingSwapData && swapData.deadline && Date.now() > Number(swapData.deadline) && tokenTo.name !== "TE") {
      console.info("Quote data expired, requesting new quote...");
      getSwapData();
    }
  }, 1000);

  const getSwapData = useDebouncedCallback(async () => {
    console.log("getSwapData() amountFrom:", amountFrom, typeof amountFrom);
    if (amountFrom.lte(0)) {
      setSwapData({ ...swapData, deadline: String(Date.now() + 30000) });
      setAmountTo(BigNumber(0));
      setExchangeRate(0);
      setIsLoadingSwapData(false);
      return;
    }

    if (tokenFrom.address === tokenTo.address) {
      setSwapData({ ...swapData, deadline: String(Date.now() + 30000) });
      setAmountTo(amountFrom);
      setExchangeRate(1);
      setIsLoadingSwapData(false);
      return;
    }

    console.log("tokenFrom", tokenFrom, tokenFrom.address);

    if (tokenTo.name === "TE") {
      if (!contractManager.contracts.TokenHolder.functions) {
        throw new Error("No TokenHolder functions in getSwapData()!");
      }

      const amountTE: ethers.BigNumber = await contractManager.contracts.TokenHolder.functions.getPriceQuote(tokenFrom.address, amountFrom);
      const amountTEPerOneAsset: ethers.BigNumber = await contractManager.contracts.TokenHolder.functions.getPriceQuote(tokenFrom.address, tokenFrom.denominator);

      setAmountTo(amountTE);
      setExchangeRate(Number(amountTEPerOneAsset) / Number(contractManager.contracts.TE.denominator));
      // if (exchangeRate <= 0.0001) {
      //   setExchangeRateDigits(6);
      // }
      // else {
      //   setExchangeRateDigits(defaultExchangeRateDigits);
      // }

      if (contractManager.contracts.TokenHolder.address === undefined) {
        throw new Error("``contractManager.contracts.TokenHolder.address`` is ``undefined`` in ``getSwapData()`` in Swap Control");
      }

      const newSwapDataDeadline: ISwapDataDeadline = {
        ...defaultSwapDataDeadline,
        to: contractManager.contracts.TokenHolder.address,
        sellAmount: amountFrom.toString(),
        buyAmount: amountTE.toString(),
        estimatedGas: tokenFrom.name === "USDC" ? "200000" : "400000",
        estimatedPriceImpact: tokenFrom.name === "USDC" ? "0" : "0.1",
        sources: [{ name: "Trendespresso", proportion: "1" }]
      };

      setSwapData(newSwapDataDeadline);
      setIsLoadingSwapData(false);

      /** 
       * Spent $1 and get 10 TE
       * 1_000_000 == amountFrom
       * 1e6*1e6/100000 == 10,000,000 == 10 TE
       */
      return;
    }
    else if (tokenFrom.name === "TE") {
      // Selling is currently disabled
      return;
    }
    else if (exchangeRateDigits !== defaultExchangeRateDigits) {
      setExchangeRateDigits(defaultExchangeRateDigits);
    }

    const URL =
      `https://avalanche.api.0x.org/swap/v1/quote?buyToken=${tokenTo.address}` +
      `&sellToken=${tokenFrom.address}` +
      `&sellAmount=${amountFrom.toString()}` +
      `&slippagePercentage=${currentSlippage}`;

    const result = await fetch(URL, { headers: TE_CONSTANTS.ZeroXAPIKeyHeader_v1 });
    const json = await result.json();

    if (result.status >= 400) {
      console.error(json);
      setSwapData(defaultSwapDataDeadline);
      setAmountTo(BigNumber(0));
      setExchangeRate(0);
      setIsLoadingSwapData(false);
      return;
    }

    console.log(json);

    const newSwapData: ISwapDataDeadline = json;
    newSwapData.deadline = (Number(newSwapData.deadline) - 1000).toString();

    setSwapData(newSwapData);
    setAmountTo(BigNumber(newSwapData.buyAmount));
    setExchangeRate(Number(newSwapData.price));
    setIsLoadingSwapData(false);
  }, 500);

  function flipFromTo() {
    const oldTokenFromBalance = tokenFromBalance;
    const oldTokenToBalance = tokenToBalance;
    const oldTokenFrom = tokenFrom;
    const oldTokenTo = tokenTo;
    const oldAmountFrom = amountFrom;
    const oldAmountTo = amountTo;

    setTokenFromBalance(oldTokenToBalance);
    setTokenToBalance(oldTokenFromBalance);
    setTokenFrom(oldTokenTo);
    setTokenTo(oldTokenFrom);
    setAmountFrom(oldAmountTo);
    setAmountTo(oldAmountFrom);
    setExchangeRate(exchangeRate ? 1 / exchangeRate : 0);

    setIsLoadingApproval(true);
    getFromApprovalAmount(oldTokenTo);

    setIsLoadingSwapData(true);
    getSwapData();
  };

  async function actionButtonClicked(actionClicked: SwapActions) {
    if (!wallet.provider) {
      throw new Error("wallet.provider is undefined in insufficientGas()!");
    }
    const balanceAvax = await wallet.provider.getBalance(wallet.address);
    let minAvaxAmount: ethers.BigNumber;
    let gaslessDialogOpen = false;
    let gaslessSwapOccurred = false;

    const insufficientGas = async () => {
      if (!contractManager.contracts.GaslessDeposit.functions) {
        throw new Error("GaslessDeposit contract is undefined in insufficientGas()!");
      }

      // TODO Could make this more modular for each blockchain
      minAvaxAmount = await contractManager.contracts.GaslessDeposit.functions.defaultAmountAVAX();

      // TODO Make this a prompt where the user must opt-in !!!!
      // TODO Make this a prompt where the user must opt-in !!!!
      // TODO Make this a prompt where the user must opt-in !!!!
      return balanceAvax.lt(minAvaxAmount.div(2));
    };

    const action = async () => {
      if (!contractManager.contracts.GaslessDeposit.functions) {
        throw new Error("GaslessDeposit contract is undefined in action()!");
      }
      const amountUsdcToSend = await contractManager.contracts.GaslessDeposit.functions.convertAmountAVAXToAmountUSDC_withFee(minAvaxAmount);
      try {
        const swapUsdcForAvaxPermit = await createPermit(
          contractManager.contracts.USDC,
          contractManager.contracts.GaslessDeposit.address,
          amountUsdcToSend
        );

        try {
          setLoader(true);
          // setTimeout(() => setLoader(true), 500);

          const res = await fetch(`${TRADING_ENGINE_BASE_URL}/gaslessDeposit`, {
            method: "POST",
            body: JSON.stringify(swapUsdcForAvaxPermit),
            headers: { "Content-type": "application/json; charset=UTF-8" }
          });

          if (res.status === 200) {
            setTimeout(() => {
              setLoader(false);
              setTimeout(() => {
                showDialog(
                  "SUCCESS",
                  "Gasless swap request submitted. Signature was successfully verified. Trading Engine is processing a small gasless swap of USDC to AVAX for you.",
                  [{
                    text: "OK",
                    type: "CONFIRM",
                    action: () => { gaslessDialogOpen = false; gaslessSwapOccurred = true; }
                  }]
                );
              }, 500);
            }, 2000);
          }
          else {
            setLoader(false);
            // throwTradeErrorOnInvalidMessage(verifyTradeSignature.deadline);
            showDialog(
              "Error",
              "GASLESS SWAP ERROR: Do you have sufficient USDC to pay for a gasless swap? Please try obtaining more USDC and Swapping again.",
              [{
                text: "OK",
                type: "CONFIRM",
                action: () => { gaslessDialogOpen = false; gaslessSwapOccurred = false; }
              }]
            );
          }
        }
        catch (error) {
          setLoader(false);
          showDialog(
            "Network Error",
            `The URL "${TRADING_ENGINE_BASE_URL}/gaslessDeposit" is inaccessible. Please alert contact@trendespresso.com`,
            [{
              text: "OK",
              type: "CONFIRM",
              action: () => { gaslessDialogOpen = false; gaslessSwapOccurred = false; }
            }]
          );
          console.log(error);
        }
      }
      catch (error) {
        showErrorMessage(error as any);
      }
    };

    try {
      if (await insufficientGas()) {
        gaslessDialogOpen = true;
        showDialog(
          "Gasless Deposit",
          <div>
            {/* <hr /> */}
            {/* <br /> */}
            Your wallet has insufficient {contractManager.contracts.Coin.name} to submit your requested Swap.{" "}
            Transactions on the blockchain cost gas which are charged in {contractManager.contracts.Coin.name} on {network.name}.{" "}
            <a
              href="https://youtu.be/3ehaSqwUZ0s"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to learn about gas.
            </a>
            <br />
            {/* <br />
            <hr /> */}
            <br />
            Would you like to swap a small amount of USDC to AVAX to enable you to process your Swap?
            <br />
            <br />
            <b className="font-weight-bold">
              {/* bold, black, strong */}
              This will cost approximately {logicallyLimitDecimals((await getPriceFromCoinbase("AVAX", "USD")) * 0.10 * 1.2)} USDC to obtain ≈ 0.1 AVAX.
            </b>
          </div>,
          [{
            text: "Yes",
            type: "CONFIRM",
            action
          },
          {
            text: "No",
            type: "CANCEL",
            action: () => { gaslessDialogOpen = false; gaslessSwapOccurred = false; }
          }]
        );
      }
    }
    catch (error) {
      console.warn(error);
    }

    // Wait for the dialog to complete via new Promise's of 250 ms each
    while (gaslessDialogOpen) {
      await new Promise(res => setTimeout(res, 250));
    }

    if (gaslessSwapOccurred) {
      setLoader(true);
    }

    // TODO Make this into a ``.subscribe`` call instead
    let count = 0;
    while (gaslessSwapOccurred) {
      await new Promise(res => setTimeout(res, 250));
      gaslessSwapOccurred = balanceAvax.eq(await wallet.provider.getBalance(wallet.address));
      count++;
      if (count >= 120 || !gaslessSwapOccurred) {
        setLoader(false);
        break;
      }
    }

    processActionButton(actionClicked);
  }

  function processActionButton(action: SwapActions) {
    switch (action) {
      case "APPROVE":
        if (contractManager.contracts.TokenHolder.address === undefined) {
          throw new Error("``contractManager.contracts.TokenHolder.address`` is ``undefined`` in ``actionButtonClicked()`` : APPROVE in Swap Control");
        }
        approve(tokenFrom, tokenTo.name === "TE" ? contractManager.contracts.TokenHolder.address : network.ZeroXExchangeProxy, amountFrom);
        break;
      case "SWAP":
        if (tokenTo.name === "TE") {
          swapTokenHolder(swapData, tokenFrom);
        }
        else {
          swap(swapData, tokenFrom);
        }

        setAmountFrom(BigNumber(0));
        setAmountFrom_str("");
        setAmountTo(BigNumber(0));
        setExchangeRate(0);
        break;
    }
  };

  let actionButton =
    tokenFrom.name === tokenTo.name ? (
      <button className={"etx-button Button fullwidth negative disabled"}>
        <div className="etx-button__icon Button__icon">
          <i className="fal fa-check-cross"></i>
        </div>
        <span className="etx-button__text Button__text">
          Invalid selection
        </span>
      </button>
    ) : tokenFrom.address.toUpperCase() !== contractManager.contracts.Coin.address.toUpperCase() && (fromApprovalAmount.lte(0) || fromApprovalAmount.lt(amountFrom)) && (tokenFrom.name !== "USDC" || tokenTo.name !== "TE") ? (
      <button className={"etx-button Button fullwidth negative"} onClick={() => actionButtonClicked("APPROVE")}>
        <div className="etx-button__icon Button__icon">
          <i className="fal fa-check-circle"></i>
        </div>
        <span className="etx-button__text Button__text">Approve</span>
      </button>
    ) : amountFrom.lte(0) ? (
      <button className={"etx-button Button fullwidth negative disabled"}>
        <div className="etx-button__icon Button__icon">
          <i className="fal fa-circle-question"></i>
        </div>
        <span className="etx-button__text Button__text">Please enter an amount</span>
      </button>
    ) : isLoadingBalance || isLoadingApproval || isLoadingSwapData ? (
      <button className={"etx-button Button fullwidth negative disabled"}>
        <div className="etx-button__icon Button__icon">
          <i className="fal fa-circle-question"></i>
        </div>
        <span className="etx-button__text Button__text">Loading...</span>
      </button>
    ) : (
      <button className={"etx-button Button fullwidth positive"} onClick={() => actionButtonClicked("SWAP")}>
        <div className="etx-button__icon Button__icon">
          <i className="fal fa-arrow-down-to-arc"></i>
        </div>
        <span className="etx-button__text Button__text">Swap</span>
      </button>
    );

  return (
    <div className="etx-container Container pt-50 pb-0">
      <div className="etx-center">
        <div className="etx-card Card bg-dark-1-60 border-radius overflow-hidden">
          <div className="etx-card__item Card__item tab-s:ph-200 tab-s:pt-200 pb-50">
            <div className="etx-flex Flex c-auto av-center gh-50 gv-25">
              <div className="etx-flex__item Flex__item c-3 shrink-0">
                <div className="etx-text Text">
                  <p className="font-weight-medium">From</p>
                </div>
              </div>

              <div className="etx-flex__item Flex__item grow-1">
                <div className="FormField">
                  <div className="FormField__label">
                    <div className="FormField__label__item right">
                      <span>Available:</span>
                      <span>{toReadableNumber(tokenFromBalance, tokenFrom.decimals)}</span>
                    </div>
                  </div>

                  <div className="FormField__input">
                    <input
                      // type="number"
                      placeholder="0"
                      value={amountFrom_str}
                      onChange={onFromAmountSet}
                      onFocus={e => e.target.select()}
                    />
                    <button
                      className="FormField__input__button"
                      onClick={setMaxCash}
                    >
                      Max
                    </button>
                  </div>

                  <div className="FormField__label"></div>
                </div>
              </div>

              <div className="etx-flex__item Flex__item shrink-0">
                <div className="FormField">
                  <div className="FormField__input select">
                    <select
                      value={tokenFrom.name.replace(".", "")}
                      onChange={onFromCurrencySet}
                    >
                      {
                        tokens.map(token =>
                          token.name === "TE"
                            ? <></>
                            : <option key={token.name} value={token.value}>
                              {token.name}
                            </option>
                        )
                      }
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="etx-card__item Card__item ph-0 pv-0">
            <div className="CurrencySwitch">
              <hr className="dotted"></hr>

              <div className="CurrencySwitch__value">
                <span>{amountFrom.gt(0) ? "1.0000" : 0} {tokenFrom.name}</span>
              </div>

              <hr className="dotted"></hr>

              <a className="CurrencySwitch__button" onClick={flipFromTo}>
                <i className="fal fa-arrows-repeat"></i>
              </a>

              <hr className="dotted"></hr>

              <div className="CurrencySwitch__value">
                <span title={exchangeRate.toFixed(exchangeRateDigits) + " " + tokenTo.name}>≈ {exchangeRate.toFixed(exchangeRateDigits)} {tokenTo.name}</span>
              </div>

              <hr className="dotted"></hr>
            </div>
          </div>

          <div className="etx-card__item Card__item tab-s:ph-200 pt-50">
            <div className="etx-flex Flex c-auto av-center gh-50 gv-25">
              <div className="etx-flex__item Flex__item c-3 shrink-0">
                <div className="etx-text Text">
                  <p className="font-weight-medium">To</p>
                </div>
              </div>

              <div className="etx-flex__item Flex__item grow-1">
                <div className="FormField">
                  <div className="FormField__label">
                    <div className="FormField__label__item left">
                      <span>Estimated</span>
                    </div>
                  </div>

                  <div className="FormField__input">
                    <input
                      type="number"
                      placeholder="0"
                      value={toReadableNumber(amountTo, tokenTo.decimals)}
                      readOnly={true}
                    />
                  </div>

                  <div className="FormField__label">
                    <div className="FormField__label__item right">
                      <span>Balance:</span>
                      <span>{toReadableNumber(tokenToBalance, tokenTo.decimals)}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="etx-flex__item Flex__item shrink-0">
                <div className="FormField">
                  <div className="FormField__input select">
                    <select
                      value={tokenTo.name.replace(/\./g, "")}
                      onChange={onToCurrencySet}
                    >
                      {
                        tokens.map((token, i) =>
                          <option key={token.name} value={token.value}>
                            {token.name}
                          </option>
                        )
                      }
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="etx-card__item Card__item ph-0 pt-0 pb-50">
            <hr className="dotted"></hr>
          </div>

          {infoExpanded === true ? <>
            <div className="etx-card__item Card__item tab-s:ph-200 pv-75">
              <div className="etx-flex Flex c-auto av-center gh-50 gv-25">

                <div className="etx-flex__item Flex__item shrink-0">
                  <div className="etx-text Text">
                    <p className="font-label">Sources</p>
                  </div>
                </div>

                <div className="etx-flex__item Flex__item grow-1">
                  <div className="etx-flex Flex c-auto gv-50 gh-50 a-right">
                    {
                      swapData.sources.filter(source => Number(source.proportion) > 0 || source.name === "N/A").map(source =>
                        <div key={source.name} className="etx-flex__item Flex__item">
                          <div className="etx-text Text">
                            <p className="font-size-large">
                              <span>{source.name}: </span>
                              <span className="font-weight-medium">{(Number(source.proportion) * 100).toFixed(0)}%</span>
                            </p>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
              <hr className="dotted"></hr>
            </div>

            <div className="etx-card__item Card__item tab-s:ph-200 pv-75">
              <div className="etx-flex Flex c-auto av-center gh-50 gv-25">
                <div className="etx-flex__item Flex__item shrink-0">
                  <div className="etx-text Text">
                    <p className="font-label">Estimated gas price</p>
                  </div>
                </div>

                <div className="etx-flex__item Flex__item grow-1">
                  <div className="etx-text Text a-right">
                    <p className="font-size-large font-weight-medium">{swapData.estimatedGas}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
              <hr className="dotted"></hr>
            </div>
            <div className="etx-card__item Card__item tab-s:ph-200 pv-75">
              <div className="etx-flex Flex c-auto av-center gh-50 gv-25">
                <div className="etx-flex__item Flex__item shrink-0">
                  <div className="etx-text Text">
                    <p className="font-label">Estimated price impact</p>
                  </div>
                </div>

                <div className="etx-flex__item Flex__item grow-1">
                  <div className="etx-text Text a-right">
                    <p className="font-size-large font-weight-medium">{swapData.estimatedPriceImpact + "%"}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
              <hr className="dotted"></hr>
            </div>

            {
              Number(swapData.estimatedPriceImpact) > 0 ? <>
                <div className="etx-card__item Card__item tab-s:ph-200 pv-75">
                  <div className="etx-flex Flex c-auto av-center gh-50 gv-25">
                    <div className="etx-flex__item Flex__item shrink-0">
                      <div className="etx-text Text">
                        <p className="font-label">Expected slippage</p>
                      </div>
                    </div>

                    <div className="etx-flex__item Flex__item grow-1">
                      <div className="etx-text Text a-right">
                        <p className="font-size-large font-weight-medium">{swapData.estimatedPriceImpact}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
                  <hr className="dotted"></hr>
                </div>
              </> : <></>
            }

            <div className="etx-card__item Card__item tab-s:ph-200 pv-75">
              <div className="etx-flex Flex c-auto av-center gh-50 gv-25">
                <div className="etx-flex__item Flex__item shrink-0">
                  <div className="etx-text Text">
                    <p className="font-label">Limit addition price slippage</p>
                  </div>
                </div>

                <div className="etx-flex__item Flex__item grow-1">
                  <div className="etx-button-list ButtonList a-right gh-50 gv-50">
                    <a className={"etx-button Button compact" + (currentSlippage === 0.1 ? " color-swap-slippage" : " transparent")}>
                      <div className="etx-button__text Button__text" onClick={() => setCurrentSlippage(0.1)}>
                        <span className="font-weight-medium">0.1%</span>
                      </div>
                    </a>

                    <a className={"etx-button Button compact" + (currentSlippage === 0.5 ? " color-swap-slippage" : " transparent")}>
                      <div className="etx-button__text Button__text" onClick={() => setCurrentSlippage(0.5)}>
                        <span className="font-weight-medium">0.5%</span>
                      </div>
                    </a>

                    <a className={"etx-button Button compact" + (currentSlippage === 1.0 ? " color-swap-slippage" : " transparent")}>
                      <div className="etx-button__text Button__text" onClick={() => setCurrentSlippage(1)}>
                        <span className="font-weight-medium">1.0%</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
              <hr className="dotted"></hr>
            </div>
          </> : <></>}

          <div className="etx-card__item Card__item tab-s:ph-200 pv-50">
            <div className="etx-button-list ButtonList a-center">
              <button className="etx-button Button transparent" onClick={() => setInfoExpanded(!infoExpanded)}>
                <span className="etx-button__text Button__text">{infoExpanded ? "Hide Details" : "Show Details"}</span>
                <div className="etx-button__icon Button__icon">
                  <i className={"fal " + (infoExpanded ? "fa-angle-up" : "fa-angle-down")}></i>
                </div>
              </button>
            </div>
          </div>

          <div className="etx-card__item Card__item ph-0 pt-50 pb-0">
            <hr className="dotted"></hr>
          </div>

          <div className="etx-card__item Card__item tab-s:ph-200 tab-s:pv-200">
            <div className="etx-button-list ButtonList">{actionButton}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
