import React from "react";
import { DepositWithdraw } from "src/pages/strategy/dialogs/DepositWithdraw";
import { Header } from "src/pages/Header";
import { PageTemplate } from "src/pages/PageTemplate";
import { Footer } from "src/pages/Footer";
import { SignalsTable } from "src/pages/SignalsTable";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DialogContext } from "src/global/contexts";
import { Sidebar } from "src/pages/strategy/Sidebar";
import { PartnerLogos } from "src/pages/PartnerLogos";

const darkTheme = createTheme({ palette: { mode: "dark" } });

export function Page() {
  const { dialogState } = React.useContext(DialogContext);

  return (
    <div className={`etx-app-wrapper ${dialogState === "OPEN" ? "overlay-show" : ""}`}>
      <Header title="Trendespresso" description={<PartnerLogos />} />

      <PageTemplate
        activeRoute="/"
        content={
          <ThemeProvider theme={darkTheme}>
            <SignalsTable />
          </ThemeProvider>
        }
        sidebar={<Sidebar />}
      />

      <Footer />
      {dialogState === "OPEN" ? <DepositWithdraw /> : <></>}
    </div>
  );
}