import React from "react";
import { Footer } from "src/pages/Footer";
import { Header } from "src/pages/Header";
import { PageTemplate } from "src/pages/PageTemplate";
import { Sidebar } from "src/pages/trade/Sidebar";
import { AcceptedFCAContext, DialogContext } from "src/global/contexts";
import { useNavigate } from "react-router-dom";
import { SignalsTable } from "src/pages/SignalsTable";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PartnerLogos } from "src/pages/PartnerLogos";

const darkTheme = createTheme({ palette: { mode: "dark" } });

// TRADE PAGE
export function Page() {
  const { acceptedFcaNotice } = React.useContext(AcceptedFCAContext);
  const { dialogState } = React.useContext(DialogContext);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (acceptedFcaNotice === false) {
      navigate("/");
    }
  }, [acceptedFcaNotice, navigate]);

  return (
    <div className={`etx-app-wrapper ${dialogState === "OPEN" ? "overlay-show" : null}`}>
      <Header title="Trade" description={<PartnerLogos />} />
      <PageTemplate activeRoute="/trade" sidebar={<Sidebar />} content={
        <ThemeProvider theme={darkTheme}>
          {/* The next line imports the entire built chart and trade table. */}
          <SignalsTable />
        </ThemeProvider>
      } />
      <Footer />
    </div>
  );
}
