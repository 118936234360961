import { MetaMask } from "@web3-react/metamask";
import type { Connector } from "@web3-react/types";
import { TE_CONSTANTS } from "./constants";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { ethers } from "ethers";
import { IPctPositionsObj } from "./types";

export function getName(connector: Connector) {
	if (connector instanceof MetaMask) return "MetaMask";
	// if (connector instanceof WalletConnect) return "WalletConnect"
	if (connector instanceof CoinbaseWallet) return "Coinbase Wallet";
	// if (connector instanceof Network) return "Network"
	// if (connector instanceof GnosisSafe) return "Gnosis Safe"
	return "Unknown";
}

export function strTrunc(str: string, length: number, inclLast: number): string {
	if (str.length > length + inclLast) {
		return str.substring(0, length) + "..." + str.substring(str.length - inclLast, str.length);
	}
	else {
		return str;
	}
}

export function displayPct(num: ethers.BigNumber, decimals: ethers.BigNumberish = TE_CONSTANTS.DEFAULT_DECIMALS) {
	decimals = BigNumber(decimals);
	const pctOne = TE_CONSTANTS.PCT_ONE_HUNDRED.div(100);
	const denominator = BigNumber(10).pow(decimals);
	const digitsToRemove = pctOne.div(denominator);

	return limitDecimals(num.div(digitsToRemove).toNumber() / denominator.toNumber(), decimals.toNumber());
}

export function limitDecimals(num: number, decimals: number = TE_CONSTANTS.DEFAULT_DECIMALS): number {
	const ret = Math.floor(num * Math.pow(10, decimals)) / Math.pow(10, decimals);

	if (isNaN(ret)) {
		return 0;
	}

	return ret;
}

export function logicallyLimitDecimals(num: number, decimals: number = TE_CONSTANTS.DEFAULT_DECIMALS): number {
	return limitDecimals(
		num,
		num >= 10000
			? 0
			: num >= 10
				? 2
				: 4
	);
}

export function toSolidityNumber(n: string, decimals: ethers.BigNumberish) {
	n = n ? n : "0";

	const regEx = new RegExp(`([0-9]*.[0-9]{${Number(decimals)}})`);
	const matches = n.match(regEx);

	if (matches) {
		n = matches[0];
	}

	try {
		return ethers.utils.parseUnits(n, Number(decimals));
	}
	catch (error) {
		console.warn(error);
		return BigNumber(0);
	}
}

export function toReadableNumber(n: ethers.BigNumber, decimals: ethers.BigNumberish) {
	return Number(ethers.utils.formatUnits(n, Number(decimals))).toString();
}

export function safeBigNumberDiv(a: ethers.BigNumber, b: ethers.BigNumberish) {
	return a.gt(b) ? a.div(b).toNumber() : 1 / BigNumber(b).div(a).toNumber();
}

export function getTradeStatus(pctPositions: IPctPositionsObj) {
	if (pctPositions.pctLong.gt(0) && pctPositions.pctShort.gt(0)) {
		return `${displayPct(pctPositions.pctLong, 0)}% Long ${displayPct(pctPositions.pctShort, 0)}% Short`;
	}
	else if (pctPositions.pctLong.gt(0)) {
		return `${displayPct(pctPositions.pctLong, 0)}% Long`;
	}
	else if (pctPositions.pctShort.gt(0)) {
		return `${displayPct(pctPositions.pctShort, 0)}% Short`;
	}
	return "Holding Cash";
	// return "-";
}

export function BigNumber(n: ethers.BigNumberish) {
	try {
		return ethers.BigNumber.from(n);
	}
	catch (error) {
		console.warn(`WARNING: BigNumber() truncated ${n}`);
		if (typeof (n) == "number") {
			return ethers.BigNumber.from(Math.trunc(n));
		}
		if (typeof (n) == "string") {
			return ethers.BigNumber.from(n.substring(0, n.indexOf(".")));
		}
		console.error("ERROR: BigNumber() failed to convert.");
		throw error;
	}
}

export function isBigNumber(obj: unknown): obj is ethers.BigNumber {
	if (obj && typeof obj === "object") {
		if (obj instanceof ethers.BigNumber) {
			return true;
		}
		else if ("_isBigNumber" in obj && obj._isBigNumber) {
			return true;
		}
		else if ("type" in obj && obj.type === "BigNumber") {
			return true;
		}
	}
	return false;
}

// For use fixing structs returned from the blockchain
export function fixSolidityStruct<T>(obj: any): T {
	if (obj instanceof ethers.BigNumber || obj._isBigNumber || obj.type === "BigNumber") { return obj; }
	const newObj: any = {};
	const onlyDigits = new RegExp(/^[0-9]+$/);

	for (const key in obj) {
		// don't keep unnamed properties
		if (onlyDigits.test(key) || key === "nft") {
			continue;
		}

		const property = obj[key];

		// keep arrays that only have unnamed properties
		if (Array.isArray(property) && isRealArray(property)) {
			newObj[key] = fixSolidityArray(property);
		}
		// recur through named properties that are objects
		else if (typeof property === "object") {
			newObj[key] = fixSolidityStruct(property);
		}
		// keep all other named properties
		else {
			newObj[key] = property;
		}
	}

	return newObj;
}

// For use fixing arrays returned from the blockchain
export function fixSolidityArray<T>(arr: any[]): T[] {
	const newArr = [];

	for (let i = 0; i < arr.length; i++) {
		const element = arr[i];

		if (Array.isArray(element) && isRealArray(element)) {
			newArr.push(fixSolidityArray(element));
		}
		else if (typeof element === "object" && !element._isBigNumber) {
			newArr.push(fixSolidityStruct(element));
		}
		else {
			newArr.push(element);
		}
	}

	return newArr;
}

// Consists only of digit-accessed properties
function isRealArray(arr: any[]): boolean {
	const onlyLetters = new RegExp(/^[a-zA-Z]+$/);

	for (const key in arr) {
		if (onlyLetters.test(key)) {
			return false;
		}
	}

	return true;
}

// Takes an array of objects and converts it into an array of arrays
export function flattenObjArray(arr: any[]) {
	const flattened = [];

	for (let i = 0; i < arr.length; i++) {
		flattened.push(flattenObj(arr[i]));
	}

	return flattened;
}

// Takes an object and converts it into an array
export function flattenObj(obj: { [key: string]: any; }) {
	const flattened = [];

	for (const key in obj) {
		if (Object.hasOwnProperty.call(obj, key)) {
			flattened.push(obj[key]);
		}
	}

	return flattened;
}

// https://stackoverflow.com/questions/21792367/replace-underscores-with-spaces-and-capitalize-words
export function humanize(str: string): string {
	var i: number, frags = str.split("_");

	for (i = 0; i < frags.length; i++) {
		frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
	}

	return frags.join(" ");
}

export async function getPriceFromCoinbase(assetName: string, cashName: string): Promise<number> {
	const assetPriceFetched = await fetch(`https://api.coinbase.com/v2/prices/${assetName}-${cashName}/spot`);

	const assetPriceFetched_JSON = await assetPriceFetched.json();

	if (assetPriceFetched_JSON.error) {
		console.warn("Coinbase response:", assetPriceFetched_JSON.error);
		return 0;
	}

	return Number(assetPriceFetched_JSON.data.amount);
}

export const GREEN_TEXT = "#7dff8a";
export const RED_TEXT = "#ff7d7d";
export const WHITE_TEXT = "white";

export function colorPnL(pnl: number) {
	return { color: pnl > 0 ? GREEN_TEXT : pnl < 0 ? RED_TEXT : WHITE_TEXT };
}

export function formatUsdcPnL(pnl: number) {
	return `${pnl > 0 ? "+" : ""}` + formatUsdc(pnl);
}

export function formatUsdc(fiat: number) {
	return (fiat / 1_000_000).toFixed(2);
}
