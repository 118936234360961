import React from "react";
import { colorPnL, getTradeStatus, limitDecimals } from "src/global/utils";
import { ContractsManagerContext, FunctionsContext, StrategyContext, SystemSummaryContext } from "src/global/contexts";
import { ISysAccountInfoObj, ISysPooledFundInfoObj } from "src/global/types";

export function Status() {
  const { contractManager } = React.useContext(ContractsManagerContext);
  const { selectedStrategy } = React.useContext(StrategyContext);
  const { SystemSummary } = React.useContext(SystemSummaryContext);
  const { tryTx } = React.useContext(FunctionsContext);

  const _getCashPnl = () => {
    let _cashPnl = 0;

    if (selectedStrategy.isPooledFund && "memberBalance" in SystemSummary.currentContract) {
      const obj = SystemSummary.currentContract as ISysPooledFundInfoObj;
      _cashPnl = obj.memberBalance.pnl;
      // _cashPnl += Number(obj.memberBalance.current) - Number(obj.memberBalance.pretrade);
      // Only works for USDC
    }
    else {
      const obj = SystemSummary.currentContract as ISysAccountInfoObj;
      _cashPnl = obj.contractBalance.pnl;
      // _cashPnl += Number(obj.contractBalance.current) - Number(obj.contractBalance.pretrade);
      // Only works for USDC
    }

    return limitDecimals(_cashPnl / contractManager.cash.denominator.toNumber());
  };

  const [cashPnl, setCashPnl] = React.useState(_getCashPnl());

  const _getIsLong = () => SystemSummary.currentContract.pctPositions.pctLong.gt(0);
  const _getIsShort = () => SystemSummary.currentContract.pctPositions.pctShort.gt(0);
  const _getTradeStatus = () => getTradeStatus(SystemSummary.currentContract.pctPositions);

  const [isLong, setIsLong] = React.useState(_getIsLong());
  const [isShort, setIsShort] = React.useState(_getIsShort());
  const [tradeStatus, setTradeStatus] = React.useState(_getTradeStatus());

  React.useEffect(() => {
    setCashPnl(_getCashPnl());
  }, [selectedStrategy.isPooledFund, contractManager.cash.denominator, SystemSummary.currentContract]);

  React.useEffect(() => {
    setIsLong(_getIsLong());
    setIsShort(_getIsShort());
    setTradeStatus(_getTradeStatus());
  }, [SystemSummary.currentContract.pctPositions, SystemSummary.currentContract.tradingEngineInfo.tradeStatus]);

  async function cancelPendingWithdrawals() {
    const contract = selectedStrategy.isPooledFund ? contractManager.contracts.PooledFund : contractManager.contracts.DepositWithdraw;

    if (!contract.functions) {
      throw new Error("No contract functions in cancelPendingWithdrawals()!");
    }

    tryTx(contract.functions.cancelPendingWithdrawals);
  }

  return (
    <div className="etx-card Card bg-white-1-20 border-radius overflow-hidden">
      <div className="etx-card__item Card__item ph-0 pv-0">
        <div className="etx-button-list ButtonList card-header">
          <button
            className="etx-button Button fullwidth border-radius-0 has-info"
            title="See all details about your account in the Bot"
          >
            <span className="etx-button__text Button__text">
              My&nbsp;&nbsp;Account&nbsp;&nbsp;Status
            </span>
            <div className="Button__info">
              <i className="far fa-info-circle"></i>
            </div>
          </button>
        </div>
      </div>

      <div className="etx-card__item Card__item pv-50">
        <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
          <div className="etx-flex__item Flex__item shrink-0">
            <div className="etx-text Text" title="While in a trade, shows your estimated cash balance. Exact PnL is shown when 'Holding Cash.'">
              <p className="font-label">{contractManager.cash.name} PnL<i className="far fa-info-circle"></i></p>
            </div>
          </div>

          {/* TODO: current pnl */}
          <div className="etx-flex__item Flex__item grow-1">
            <div className="etx-text Text a-right">
              <p className="font-size-large font-weight-medium" style={colorPnL(cashPnl)}>
                {cashPnl}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="etx-card__item Card__item ph-0 pv-0">
        <hr />
      </div>

      <div className="etx-card__item Card__item pv-50">
        <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
          <div className="etx-flex__item Flex__item shrink-0">
            <div className="etx-text Text">
              <p className="font-label">Current Trade</p>
            </div>
          </div>

          <div className="etx-flex__item Flex__item grow-1">
            <div className="etx-text Text a-right">
              <p className="font-size-large font-weight-medium">
                {
                  isLong && isShort
                    ? <span style={{ fontSize: "0.8em" }}>{tradeStatus}</span>
                    : tradeStatus
                }
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="etx-card__item Card__item ph-0 pv-0">
        <div className="etx-button-list ButtonList">
          <button
            className="etx-button Button fullwidth border-radius-0 has-info cancelPendingTransfersColour"
            title="Cancels any pending transfers from the Bot."
            onClick={() => cancelPendingWithdrawals()}
          >
            <div className="etx-button__icon Button__icon">
              <i className="far fa-times"></i>
            </div>
            <span className="etx-button__text Button__text" style={{ fontSize: "12px" }}>Cancel Pending Withdrawals</span>
            {/* <div className="Button__info">
              <i className="far fa-info-circle"></i>
            </div> */}
          </button>
        </div>
      </div>

    </div>
  );
}
