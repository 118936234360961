import React from "react";
import { WalletContext } from "src/global/contexts";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

interface IProps {
  elements: ReactJSXElement[];
}

const defaultProps: IProps = {
  elements: [<></>],
};

export function SidebarTemplate(props = defaultProps) {
  const { wallet } = React.useContext(WalletContext);

  return (
    <aside className="etx-body__aside">
      {
        wallet?.connectionState !== "CONNECTED"
          ? <div className="etx-container Container pv-0"></div>
          :
          <>
            {props.elements.map((e, i) => {
              return (
                <div className={`etx-container Container ${i === 0 ? "pv-0" : "pt-50 tab-m:pt-25 pb-0"}`} key={new Date().getTime() - i}>
                  {e}
                </div>
              );
            })}
          </>
      }
    </aside>
  );
}
