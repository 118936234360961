import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <>
    <React.StrictMode>
      <div className="Background">
        <div className="Background__ball blue-1"></div>
        <div className="Background__ball blue-2"></div>
        <div className="Background__ball blue-3"></div>
        <div className="Background__ball red-1"></div>
        <div className="Background__ball red-2"></div>

        <div className="Background__light"></div>

        <div className="Background__blur"></div>
      </div>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
