import { createContext } from "react";
import {
	ConnectionState,
	DepositWithdrawDialogTab,
	DialogState,
	ERC2612PermitMessage_RSV,
	IRPCError,
	ISystemSummaryObj,
	IWallet,
	defaultSystemSummary,
	defaultWallet
} from "src/global/types";
import { ContractManager, IContract, ITokenContract } from "src/ABIs/contract_map";
import { ethers } from "ethers";
import { DEFAULT_STRATEGY_INDEX, IStrategy, strategies } from "src/global/strategies";
import { INetwork, defaultNetwork } from "src/global/constants";
import { ISwapData } from "src/pages/swap/content/Control";
import { IDialogAction } from "src/pages/DialogTemplate";

// FCA compliance context
export interface AcceptedFCAContextData { acceptedFcaNotice?: boolean; onFcaNoticeAccepted: () => void; };
export const AcceptedFCAContext = createContext<AcceptedFCAContextData>({
	acceptedFcaNotice: undefined,
	onFcaNoticeAccepted: () => console.warn("onFcaNoticeAccepted not bound in AcceptedFCAContext")
});

// Loader context
export interface LoaderContextData { isLoading: boolean; };
export const LoaderContext = createContext<LoaderContextData>({ isLoading: false });

// Wallet context
export interface WalletContextData { wallet: IWallet; };
export const WalletContext = createContext<WalletContextData>({ wallet: defaultWallet });

// Network context
export interface NetworkContextData { network: INetwork; };
export const NetworkContext = createContext<NetworkContextData>({ network: defaultNetwork });

// Strategy context
export interface StrategyContextData { selectedStrategy: IStrategy; setStrategy: (selectedStrategy: IStrategy) => void; };
export const StrategyContext = createContext<StrategyContextData>({ selectedStrategy: strategies[DEFAULT_STRATEGY_INDEX], setStrategy: (a) => console.warn("setStrategy not bound in StrategyContext") });

// Contracts context
export interface ContractsManagerContextData { contractManager: ContractManager; };
export const ContractsManagerContext = createContext<ContractsManagerContextData>({ contractManager: new ContractManager(defaultNetwork) });

// SystemSummary context
export interface SystemSummaryContextData { SystemSummary: ISystemSummaryObj; };
export const SystemSummaryContext = createContext<SystemSummaryContextData>({ SystemSummary: defaultSystemSummary });

// Dialog context
export interface DialogContextData { dialogState: DialogState; dialogTab: DepositWithdrawDialogTab; }
export const DialogContext = createContext<DialogContextData>({ dialogState: "CLOSED", dialogTab: "DEPOSIT" });

// Functions context
export type OverlayType = "FAQ" | "WALLET-SELECTION" | null;
export interface FunctionsContextData {
	tryTx: (method: ethers.ContractFunction, params?: any[], value?: string) => Promise<void>;
	approve: (tokenContract: ITokenContract, addressToApprove: string, approvalAmount: ethers.BigNumberish) => void;
	createPermit: (tokenContract: ITokenContract, addressToApprove: string, approvalAmount: ethers.BigNumberish) => Promise<ERC2612PermitMessage_RSV>;
	showErrorMessage: (error: IRPCError) => void;
	showDialog: (title: string | JSX.Element, content: string | JSX.Element, actions?: IDialogAction[]) => void;
	setActiveOverlay: (overlay: OverlayType) => void;
	toggleDepositWithdrawDialog: (tab: DepositWithdrawDialogTab) => void;
	switchDepositWithdrawDialogTab: (tab: DepositWithdrawDialogTab) => void;
	subscribeToBlockchain: (callback: (...args: any[]) => Promise<void> | void) => void;
	unsubscribeToBlockchain: (callback: (...args: any[]) => Promise<void> | void) => void;
	setWalletConnectionState: (connectionState: ConnectionState) => void;
	setLoader: (isLoading: boolean) => void;
};
export const FunctionsContext = createContext<FunctionsContextData>({
	tryTx: (a, b?, c?) => new Promise(() => console.warn("tryTx not bound in FunctionsContext")),
	approve: (a, b, c) => console.warn("approve not bound in FunctionsContext"),
	createPermit: (a, b, c) => new Promise(() => console.warn("createPermit not bound in FunctionsContext")),
	showErrorMessage: (a) => console.warn("showErrorMessage not bound"),
	showDialog: (a, b, c?) => console.warn("showDialog not bound in FunctionsContext"),
	setActiveOverlay: (a) => console.warn("setActiveOverlay not bound in FunctionsContext"),
	toggleDepositWithdrawDialog: (a) => console.warn("toggleDepositWithdrawDialog not bound in FunctionsContext"),
	switchDepositWithdrawDialogTab: (a) => console.warn("toggleDepositWithdrawDialog not bound in FunctionsContext"),
	subscribeToBlockchain: (a) => console.warn("subscribeToBlockchain not bound in FunctionsContext"),
	unsubscribeToBlockchain: (a) => console.warn("unsubscribeToBlockchain not bound in FunctionsContext"),
	setWalletConnectionState: (a) => console.warn("setWalletConnectionState not bound in FunctionsContext"),
	setLoader: (a) => console.warn("setLoader not bound in FunctionsContext")
});

// SwapFunctions context
export interface SwapFunctionsContextData {
	getTokenApprovedAmount: (token: ITokenContract, approvedAddress?: string) => Promise<ethers.BigNumber>;
	getTokenBalance: (token: ITokenContract) => Promise<ethers.BigNumber>;
	swapTokenHolder: (swapData: ISwapData, fromToken: IContract) => Promise<void>;
	swap: (swapData: ISwapData, fromToken: IContract) => Promise<void>;
}
export const SwapFunctionsContext = createContext<SwapFunctionsContextData>({
	getTokenApprovedAmount: (a, b?) => new Promise(() => console.warn("getTokenApprovedAmount is not bound in SwapFunctionsContext")),
	getTokenBalance: (a) => new Promise(() => console.warn("getTokenBalance is not bound in SwapFunctionsContext")),
	swapTokenHolder: (a) => new Promise(() => console.warn("swapTokenHolder is not bound in SwapFunctionsContext")),
	swap: (a, b) => new Promise(() => console.warn("swap is not bound in SwapFunctionsContext"))
});

// CurrentBlockchain context
export interface CurrentBlockchainContextData {
	blockNumber: number;
}
export const CurrentBlockchainContext = createContext<CurrentBlockchainContextData>({
	blockNumber: 0
});