import React from "react";
import { FunctionsContext } from "src/global/contexts";
import { WalletProviderDrawer } from "src/pages/WalletProviderDrawer";

export function WalletAndFaq() {
  const { setActiveOverlay } = React.useContext(FunctionsContext);

  return (
    <>
      <div className="FaqIndicator" onClick={() => setActiveOverlay("FAQ")}>
        <div className="FaqIndicator__text">
          <p className="font-weight-medium">FAQ</p>
        </div>

        <div className="FaqIndicator__icon">
          <i className="fal fa-question"></i>
        </div>
      </div>

      <WalletProviderDrawer />
    </>
  );
}