import React from "react";

const ZeroXLogo: string = require("src/assets/img/logo_0x.svg").default;
const AAVELogo: string = require("src/assets/img/logo_aave.svg").default;

export function PartnerLogos() {
  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: "-28px" }}>
      <div style={{ marginRight: "20px" }}>
        <a href="https://0x.org/" target="_blank" rel="noreferrer">
          <img
            // src="https://assets.website-files.com/640bf70a17d12b42d97a052b/641a220f0ec6db351732ce83_brand-powered-by-0x-white.svg"
            src={ZeroXLogo}
            alt="0x"
            className="headerPartner__0x"
          />
        </a>
      </div>

      <div style={{ marginRight: "20px" }}>
        <a href="https://aave.com/" target="_blank" rel="noreferrer">
          <img
            // src="https://aave.com/aaveGhost.svg"
            src={AAVELogo}
            alt="AAVE"
            className="headerPartner__aave"
          />
        </a>
      </div>
    </div>
  );
}