import React from "react";

interface IProps {
  title: string;
  children: JSX.Element;
};

export function FaqAccordion(props: IProps) {
  const [isActive, setIsActive] = React.useState(false);

  return (
    <div className={isActive ? "etx-accordion Accordion active" : "etx-accordion Accordion"}>
      <div className="etx-accordion__header Accordion__header" onClick={() => setIsActive(!isActive)}>
        <div className="etx-accordion__header__title Accordion__header__title">
          <p className="font-weight-bold font-size-large">{props.title}</p>
        </div>

        <div className="etx-accordion__header__button Accordion__header__button">
          {isActive ? <i className="fal fa-angle-up"></i> : <i className="fal fa-angle-down"></i>}
        </div>
      </div>

      {isActive ? <div className="etx-accordion__body Accordion__body">{props.children}</div> : <></>}
    </div>
  );
}