import React from "react";
import { Modify } from "src/global/helpers";

const _defaultDialogOK = {
  text: "OK",
  type: "CONFIRM" as "CONFIRM",
  action: () => { }
};
export type IDialogAction = Modify<typeof _defaultDialogOK, { type: "CONFIRM" | "CANCEL"; }>;
export const defaultDialogOK: IDialogAction = _defaultDialogOK;

export const defaultDialogCANCEL: IDialogAction = {
  text: "Cancel",
  type: "CANCEL",
  action: () => { }
};

const _defaultDialogActions = [
  defaultDialogOK,
  defaultDialogCANCEL
];
export type IDialogActions = typeof _defaultDialogActions;
export const defaultDialogActions: IDialogActions = _defaultDialogActions;

const _defaultDialogMessage = {
  isOpen: false,
  titleText: "",
  titleHtml: <></>,
  contentText: "",
  contentHtml: <></>,
  actions: defaultDialogActions
};
export type IDialogMessage = typeof _defaultDialogMessage;
export const defaultDialogMessage: IDialogMessage = _defaultDialogMessage;

const _defaultDialogProps = {
  message: _defaultDialogMessage
};
export type IDialogProps = typeof _defaultDialogProps;
export const defaultDialogProps: IDialogProps = _defaultDialogProps;

export function DialogTemplate(props = defaultDialogProps) {
  return (
    <div className="Overlay">
      <div className="Overlay__background"></div>

      <div className="Overlay__content" id="DialogAnchor">
        <div className="etx-card Card bg-dark-1-95 border-radius overflow-hidden">
          <div className="etx-card__item Card__item tab-s:ph-200 tab-s:pv-200">
            <div className="etx-text Text">
              {props.message.titleText ? <p className="font-h3">{props.message.titleText}</p> : props.message.titleHtml}
              {props.message.contentText ? <p>{props.message.contentText}</p> : props.message.contentHtml}
            </div>
          </div>

          <div className="etx-card__item Card__item ph-0 pv-0">
            <div className="etx-button-list ButtonList combo">
              {
                props.message?.actions?.map(action => {
                  switch (action.type) {
                    case "CONFIRM":
                      return <a key={action.text} className="etx-button Button border-radius-0 OKbutton" onClick={() => action.action()}>
                        <span className="etx-button__text Button__text">OK</span>
                      </a>;
                    case "CANCEL":
                      return <a key={action.text} className="etx-button Button border-radius-0 negative" onClick={() => action.action()}>
                        <span className="etx-button__text Button__text">Cancel</span>
                      </a>;
                  }
                  return <></>;
                })
                ??
                <a className="etx-button Button border-radius-0 OKbutton">
                  <span className="etx-button__text Button__text">OK</span>
                </a>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
