import React from "react";
import { colorPnL, formatUsdc, formatUsdcPnL } from "src/global/utils";
import { SidebarTemplate } from "src/pages/SidebarTemplate";
import { elements } from "src/pages/strategy/Sidebar";

export interface IProps {
  totalUsdValue: number;
  totalUsdPnl: number;
}

export const defaultProps: IProps = {
  totalUsdValue: 0,
  totalUsdPnl: 0
};

export function Sidebar(props = defaultProps) {
  return <SidebarTemplate
    elements={[
      <div className="etx-card Card bg-white-1-20 border-radius overflow-hidden">
        <div className="etx-card__item Card__item pv-50 bg-white-1-20">
          <div className="etx-text Text a-center">
            <h5>Your&nbsp;&nbsp;Total&nbsp;&nbsp;Funds</h5>
          </div>
        </div>

        <div className="etx-card__item Card__item pv-50">
          <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
            <div className="etx-flex__item Flex__item shrink-0">
              <div className="etx-text Text">
                <p className="font-label">Total USD Value</p>
              </div>
            </div>

            <div className="etx-flex__item Flex__item grow-1">
              <div className="etx-text Text a-right">
                <p className="font-size-large font-weight-medium">
                  {formatUsdc(props.totalUsdValue)}
                </p>
              </div>
            </div>

          </div>

        </div>

        <div className="etx-card__item Card__item ph-0 pv-0">
          <hr />
        </div>

        <div className="etx-card__item Card__item pv-50">
          <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
            <div className="etx-flex__item Flex__item shrink-0">
              <div className="etx-text Text">
                <p className="font-label">Total USD PnL</p>
              </div>
            </div>

            <div className="etx-flex__item Flex__item grow-1">
              <div className="etx-text Text a-right">
                <p className="font-size-large font-weight-medium" style={colorPnL(props.totalUsdPnl)}>
                  {formatUsdcPnL(props.totalUsdPnl)}
                </p>
              </div>
            </div>

          </div>

        </div>
      </div>,
      ...elements
    ]}
  />;
}